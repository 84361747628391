import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';

export interface CardSelectProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title: any;
    subText?: any;
    outline?: boolean;
    icon?: IconDefinition;
    rounded?: boolean;
    noImgFade?: boolean;
    onChange: () => void;
    isActive?: boolean;
}

const CardSelect = forwardRef<HTMLDivElement, CardSelectProps>((props, ref) => {

    const { title, subText, outline, children, icon, rounded, noImgFade, onChange, isActive, ...restProps } = props;

    return (
        <div
            ref={ref}
            {...restProps}
            className={`
                flex flex-col relative p-3 cursor-pointer overflow-hidden group border-[1px] border-[#D7E3F2] transition-all
                ${outline && 'border-[1px] border-light-250'} 
                ${rounded && 'rounded-[0.4rem]'} 
                ${isActive
                    ? 'bg-blue-100'
                    : 'bg-light-200 focus:bg-gray-200 hover:bg-gray-200'
                }
                ${restProps.className}
            `}
            tabIndex={0}
            onClick={onChange}
            onKeyDown={(e) => e.key === 'Enter' && onChange()}
        >
            <label className='cursor-pointer flex justify-between pr-3 mb-3 z-[2]'>
                <div>
                    <input
                        tabIndex={-1}
                        className="mr-2 accent-blue-500"
                        type="radio"
                        name="role"
                        value="ROLE_SOCIAL"
                        checked={isActive}
                    />
                    {title}
                </div>
                {icon && <FontAwesomeIcon icon={icon} className={`ml-2 transition ${isActive ? 'opacity-75' : 'opacity-25'}`} />}
            </label>

            {subText && <p className="ml-1 text-sm text-gray-600 z-[2]">{subText}</p>}

            {icon &&
                <>
                    <FontAwesomeIcon className='absolute -bottom-6 left-2 -rotate-12 text-black opacity-[4%] text-[150px] mt-1.5 transition group-hover:scale-125 group-focus:scale-125' icon={icon} />
                    {!noImgFade && <div className={`absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-light-200 to-transparent transition duration-500 group-hover:opacity-0 ${isActive && 'opacity-0'}`} />}
                </>
            }
        </div>
    );
});

export default CardSelect;
