import { useTranslation } from "react-i18next";
import IconSVG from "../../../Assets/icon.svg?component";
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useState } from "react";
import { JWT } from '../../../Utils/JWT';
import { AnimatePresence, motion } from "framer-motion";
import digipaslogo from "../../../Assets/digipaslogo.png";
import { Person } from "@mui/icons-material";
import { useLazyGetOrganisationSettingsQuery } from "../../../Redux/Api/Organisations";
import { useAppSelector } from "../../../Redux/store";
import LoadingPage from "../../Pages/LoadingPage";

interface MyCardProps {
    customSponsor?: boolean;
    sponsorTitle?: string;
    sponsorImage?: string;
    customName?: string;
    customDisplayMode?: string;
    customCardInfo1?: string;
    customCardInfo2?: string;
    customCardInfo3?: string;
    customCardInfo4?: string;
    customCardData?: boolean;
    customNameStatic?: string;
    children?: React.ReactNode;
}

const MyCard: React.FC<MyCardProps> = ({ customSponsor, sponsorTitle, sponsorImage, customName, customDisplayMode, customCardInfo1, customCardInfo2, customCardInfo3, customCardInfo4, customCardData, customNameStatic, children }) => {
    const { t, i18n } = useTranslation();
    const first_name = JWT.read<string>('given_name');
    const last_name = JWT.read<string>('family_name');
    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery();
    const org_id = useAppSelector((s) => s.organisation.id);

    const [openQRCode, setOpenQRCode] = useState(false);
    const [openImage, setOpenImage] = useState(false);

    const [maxNumber, setMaxNumber] = useState<number>();
    const [name, setName] = useState('name');
    const [hasSponsor, setHasSponsor] = useState(false);
    const [displayMode, setDisplayMode] = useState('qrOnly');
    const [cardInfo1, setCardInfo1] = useState('name');
    const [cardInfo2, setCardInfo2] = useState('team');
    const [cardInfo3, setCardInfo3] = useState('type');
    const [cardInfo4, setCardInfo4] = useState('date');

    useEffect(() => {
        if (org_id) { getOrganisationsSettings(org_id) }
    }, [org_id, settings])

    useEffect(() => {
        if (settings) {
            setName(settings.name);
            settings.photo ? setDisplayMode(settings.displayMode) : setDisplayMode('qrOnly');
            setHasSponsor(!!settings.sponsors)
            setCardInfo1(settings.cardInfo[0]);
            setCardInfo2(settings.cardInfo[1]);
            setCardInfo3(settings.cardInfo[2]);
            setCardInfo4(settings.cardInfo[3]);
            setMaxNumber(settings.maxNumberOfPeople);
        }
    }, [settings])

    const welcomeMessage = (name === 'name' ? `${first_name} ${last_name}` : (name === 'firstname' ? first_name : (name === 'lastname' ? last_name : '123456')));
    const customWelcomeMessage = (customName === 'name' ? `${first_name} ${last_name}` : (customName === 'firstname' ? first_name : (customName === 'lastname' ? last_name : '123456')));

    const buildData = (data: string): JSX.Element => {
        const today = new Date();
        const oneYearLater = new Date(today);
        oneYearLater.setFullYear(today.getFullYear() + 1);
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(today.getFullYear() - 1);

        const formatDate = (date: Date): string => {
            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString(i18n.language === 'en' ? 'en-GB' : i18n.language, options);
        };

        const locationMap: Record<string, string> = {
            nl: 'Nederland',
            de: 'Deutschland',
            es: 'España',
            fr: 'France',
            en: 'United Kingdom',
        };

        const getLocationString = (lang: string): string => locationMap[lang] || lang;

        let title = '';
        let subtitle = '';

        switch (data) {
            case 'numberOfPeople':
                title = t('preview:numberOfPeople');
                subtitle = `${maxNumber}`;
                break;
            case 'types':
                title = t('preview:types');
                subtitle = 'Digipasser';
                break;
            case 'membershipId':
                title = t('preview:membershipId');
                subtitle = '123456';
                break;
            case 'team':
                title = t('preview:team');
                subtitle = i18n.language.toLocaleUpperCase();
                break;
            case 'activeFrom':
                title = t('preview:activeFrom');
                subtitle = formatDate(today);
                break;
            case 'activeUntil':
                title = t('preview:activeUntil');
                subtitle = formatDate(oneYearLater);
                break;
            case 'email':
                title = t('preview:email');
                subtitle = first_name?.toLocaleLowerCase() + '@digipas.app';
                break;
            case 'dateOfBirth':
                title = t('preview:dateOfBirth');
                subtitle = '01-01-2000';
                break;
            case 'telephoneNumber':
                title = t('preview:telephoneNumber');
                subtitle = '06 12345678';
                break;
            case 'name':
                title = t('preview:name');
                subtitle = `${first_name} ${last_name}`;
                break;
            case 'department':
                title = t('preview:department');
                subtitle = 'Scans';
                break;
            case '':
                return <></>;
            case 'empty':
                return <></>;
            case 'location':
                title = t('preview:location');
                subtitle = getLocationString(i18n.language);
                break;
            case 'discount':
                title = t('preview:discount');
                subtitle = 'Digi50';
                break;
            case 'createdAt':
                title = t('cards:list:createdAt');
                subtitle = formatDate(oneYearAgo);
                break;
            case 'initials':
                title = t('preview:name');
                subtitle = `${first_name}`;
                break;
            case 'lastname':
                title = t('preview:name');
                subtitle = `${last_name}`;
                break;
            default:
                title = t('preview:name');
                subtitle = `${first_name} ${last_name}`;
                break;
        }

        return (
            <div className="flex flex-col text-start break-all">
                <p className="text-gray-500 text-[14px]">{title}</p>
                <h2 className="text-[14px] font-app-bold">{subtitle}</h2>
            </div>
        );
    };

    const parseContent = (content: string) => {
        return content.replace(/ /g, '\u00A0');
    };

    if (!settings || !maxNumber) return <></>;

    return (
        <>
            {hasSponsor && (
                <>
                    <div className="w-full pt-2 ml-[27px] text-[12px] text-black">Sponsor:</div>
                    <div className="relative bg-white p-1 pr-0 h-[60px] flex justify-center items-center">
                        {customSponsor ? (
                            <>
                                {sponsorImage && <img src={sponsorImage} className="w-[45px] h-[45px] object-contain" />}
                                <div className="pr-1 max-w-[calc(100%-50px)] overflow-hidden">{parseContent(sponsorTitle || '')}</div>
                                {sponsorImage && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 0.4 }} className="text-xs absolute left-1/2 transform -translate-x-1/2 whitespace-nowrap -bottom-4">{t('sponsor:previewInfo')}</motion.div>}
                            </>
                        ) : (
                            <>
                                <img src={digipaslogo} className="w-[45px] h-[45px] object-contain" />
                                <div className="pr-1 max-w-[calc(100%-50px)] overflow-hidden">&nbsp;Digipas</div>
                            </>
                        )}
                    </div>
                </>
            )}


            <div className="p-[20px]">
                <div className="font-app-bold text-[24px]">{t('cards:app:hi')} {customNameStatic ? customNameStatic : customName ? customWelcomeMessage : welcomeMessage}</div>
                <div className="text-[15px] text-gray-500">{t('cards:app:welcomeMessage')}</div>
            </div>

            <div className="h-[453px] overflow-y-auto no-scrollbar">
                <div className="p-5 bg-white mx-[10px] overflow-hidden">
                    <div className="flex justify-center items-center">
                        {customDisplayMode ? (
                            customDisplayMode === 'qrOnly' ? (
                                <QRCodeCanvas onClick={() => setOpenQRCode(true)} className="cursor-pointer" value="https://www.youtube.com/watch?v=dQw4w9WgXcQ" id="qrcode" size={170} level={"H"} />
                            ) : (customDisplayMode === 'qrAndPhoto' ? (
                                <div className='flex gap-8'>
                                    <QRCodeCanvas onClick={() => setOpenQRCode(true)} className="cursor-pointer" value="https://www.youtube.com/watch?v=dQw4w9WgXcQ" id="qrcode" size={140} level={"H"} />
                                    <Person onClick={() => setOpenImage(true)} className="cursor-pointer" style={{ width: 140, height: 140, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray' }} />
                                </div>
                            ) : (
                                <Person onClick={() => setOpenImage(true)} className="cursor-pointer" style={{ width: 170, height: 170, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray' }} />
                            ))) : (
                            displayMode === 'qrOnly' ? (
                                <QRCodeCanvas onClick={() => setOpenQRCode(true)} className="cursor-pointer" value="https://www.youtube.com/watch?v=dQw4w9WgXcQ" id="qrcode" size={170} level={"H"} />
                            ) : (displayMode === 'qrAndPhoto' ? (
                                <div className='flex gap-8'>
                                    <QRCodeCanvas onClick={() => setOpenQRCode(true)} className="cursor-pointer" value="https://www.youtube.com/watch?v=dQw4w9WgXcQ" id="qrcode" size={140} level={"H"} />
                                    <Person onClick={() => setOpenImage(true)} className="cursor-pointer" style={{ width: 140, height: 140, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray' }} />
                                </div>
                            ) : (
                                <Person onClick={() => setOpenImage(true)} className="cursor-pointer" style={{ width: 170, height: 170, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray' }} />
                            )))}
                    </div>


                    <div className="relative flex items-center justify-center mt-[25px]">
                        <div className="absolute -left-0 -translate-x-full bg-[#F6F8FA] rounded-full w-[40px] h-[40px]" />
                        <div className="absolute right-0 translate-x-full bg-[#F6F8FA] rounded-full w-[40px] h-[40px]" />

                        <p className="flex items-center opacity-20"><IconSVG /><span className="text-[16px] ml-2">D I G I P A S</span></p>
                    </div>

                    <div className="pt-8 pb-5 px-5 w-full flex items-center justify-center">
                        <div className="w-[300px] grid grid-cols-2 gap-x-2 gap-y-4">
                            {customCardData
                                ? <>{children}</>
                                : <>
                                    {buildData(customCardInfo1 || cardInfo1)}
                                    {buildData(customCardInfo2 || cardInfo2)}
                                    {buildData(customCardInfo3 || cardInfo3)}
                                    {buildData(customCardInfo4 || cardInfo4)}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {openQRCode && (
                    <motion.div onClick={() => setOpenQRCode(false)} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ type: "ease", duration: 0.2 }} className="cursor-pointer absolute z-10 top-0 right-0 left-0 bottom-0 bg-black/20 flex justify-center items-center">
                        <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ type: "ease", duration: 0.3 }} className="bg-white p-5 rounded-[10px]">
                            <QRCodeCanvas value="https://www.youtube.com/watch?v=dQw4w9WgXcQ" id="qrcode" size={310} level={"H"} />
                        </motion.div>
                    </motion.div>
                )}

                {openImage && (
                    <motion.div onClick={() => setOpenImage(false)} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ type: "ease", duration: 0.2 }} className="cursor-pointer absolute z-10 top-0 right-0 left-0 bottom-0 bg-black/20 flex justify-center items-center">
                        <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} transition={{ type: "ease", duration: 0.3 }} className="bg-white p-5 rounded-[10px]">
                            <Person style={{ width: 310, height: 310, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray' }} />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default MyCard;
