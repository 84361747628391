import { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import nlflag from '../../Assets/nl.svg';
import enflag from '../../Assets/en.svg';
import deflag from '../../Assets/de.svg';
import esflag from '../../Assets/es.svg';
import frflag from '../../Assets/fr.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = ({ style, className }: { style?: React.CSSProperties, className?: string }) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language.toUpperCase());
    const navigate = useNavigate();

    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
        const currentLang = pathSegments[1]?.toLowerCase();
        const validLanguages = ['nl', 'en', 'de', 'fr', 'es'];
        const storedLang = localStorage.getItem('preferredLanguage');

        if (validLanguages.includes(currentLang)) {
            if (currentLang !== i18n.language) {
                i18n.changeLanguage(currentLang).then(() => {
                    setLanguage(currentLang.toUpperCase());
                    localStorage.setItem('preferredLanguage', currentLang);
                });
            }
        } else if (storedLang && validLanguages.includes(storedLang) && storedLang !== i18n.language && storedLang !== 'null' && storedLang !== null && storedLang !== undefined) {
            i18n.changeLanguage(storedLang).then(() => {
                setLanguage(storedLang.toUpperCase());
            });
        } else {
            i18n.changeLanguage('nl').then(() => {
                setLanguage('nl'.toUpperCase());
            });
        }
    }, [i18n]);

    const changeLanguage = (event: SelectChangeEvent) => {
        const selectedLang = event.target.value?.toLowerCase() || 'nl';
        i18n.changeLanguage(selectedLang);
        setLanguage(selectedLang.toUpperCase());
        localStorage.setItem('preferredLanguage', selectedLang);

        const pathSegments = window.location.pathname.split('/');
        const currentLang = pathSegments[1]?.toLowerCase();

        if (currentLang !== selectedLang) {
            navigate(`/${selectedLang}${window.location.pathname.slice(3)}`);
        }
    };

    return (
        <FormControl style={style} className={`cursor-pointer bg-[#FFFFFF] rounded-[0.4rem] ${className}`} sx={{ width: 75 }}>
            <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                onChange={changeLanguage}
                sx={{ boxShadow: 'none', border: '1px solid #D7E3F2' }}
            >
                <MenuItem value="NL">
                    <img src={nlflag} alt="Dutch" style={{ width: 25, height: 20 }} />
                </MenuItem>
                <MenuItem value="EN">
                    <img src={enflag} alt="English" style={{ width: 27, height: 23 }} />
                </MenuItem>
                <MenuItem value="DE">
                    <img src={deflag} alt="German" style={{ width: 25, height: 20 }} />
                </MenuItem>
                <MenuItem value="FR">
                    <img src={frflag} alt="French" style={{ width: 25, height: 20 }} />
                </MenuItem>
                <MenuItem value="ES">
                    <img src={esflag} alt="Spanish" style={{ width: 27, height: 20 }} />
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageDropdown;