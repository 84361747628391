import { useTranslation } from "react-i18next";
import { useGetNotificationsQuery, useLazyGetOrganisationQuery } from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store';
import { DeviceFrameset } from 'react-device-frameset';
import IconSVG from "../../../../Assets/icon.svg?component";
import React, { useState, useEffect } from "react";
import LoadingPage from "../../LoadingPage";
import MobileApp from "../../../Shared/App/MobileApp";
import { OrganisationResponseItem } from "../../../../Redux/Api/Request/Organisations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

interface Notification {
    id: string;
    title: string;
    data: string;
    text: string;
    sentAt: {
        date: string;
    };
    isSent: boolean;
    scheduledAt: string;
}

interface NotifyHistoryProps {
    title: string;
    content: string;
    customDate: Boolean
}

const NotifyHistory: React.FC<NotifyHistoryProps> = ({ title, content, customDate }) => {
    const { t, i18n } = useTranslation();
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const { data = [], isFetching, refetch } = useGetNotificationsQuery(org_id!, {
        skip: !org_id,
    }) as { data?: Notification[]; isFetching: boolean; refetch: () => void };
    const [getOrganisation] = useLazyGetOrganisationQuery();
    const [activeOrganisation, setActiveOrganisation] = useState<OrganisationResponseItem>();
    const [focus, setFocus] = useState<string>();

    useEffect(() => {
        if (org_id) {
            refetch();
        }
    }, [org_id, refetch]);

    const getOrganisationName = (id: string) => {
        getOrganisation({ id }).then((data) => {
            setActiveOrganisation(data.data!);
        });
    };

    useEffect(() => {
        if (!org_id) return;
        getOrganisationName(org_id);
    }, [org_id]);

    if (!org_id || isFetching || !data || !activeOrganisation) { return <></>; }

    const lastNotifications = [...data]
        .sort((a, b) => new Date(b.sentAt.date).getTime() - new Date(a.sentAt.date).getTime())
        .slice(0, 5);

    const changeFocus = (id: string) => {
        if (focus === id) {
            setFocus('');
            return;
        }
        setFocus(id);
    }

    const buildDate = (date: Date) => {
        let lang = i18n.language || 'nl';
        if (lang === 'en') {
            lang = 'en-GB';
        }

        const notificationDate = new Date(date);
        const today = new Date();

        const isToday =
            notificationDate.getDate() === today.getDate() &&
            notificationDate.getMonth() === today.getMonth() &&
            notificationDate.getFullYear() === today.getFullYear();

        return isToday
            ? notificationDate.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })
            : notificationDate.toLocaleDateString(lang, { month: 'short', day: 'numeric' });
    }

    const GenerateNotificationItem = (id: string, title: string, text: string, date?: Date, isSent?: boolean, customDate?: Boolean) => {
        const fullTitle = `${activeOrganisation?.name}: ${title}`;

        return (
            <div key={id} onClick={() => changeFocus(id)} className={`relative ${(fullTitle.length > 30 || text.length > 65) && 'cursor-pointer'} bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md flex items-center ${(isSent ? !isSent : !customDate) && 'opacity-75'} ${id === 'preview' && 'mb-10'}`}>
                <div className={`${focus === id && 'opacity-0'} bg-white p-0.5 rounded-full w-[40px] h-[40px] flex items-center justify-center scale-75`}>
                    <IconSVG className="text-brand w-6 h-6" />
                </div>
                {focus === id && (
                    <div className="absolute top-4 bg-white p-0.5 rounded-full w-[40px] h-[40px] flex items-center justify-center scale-75">
                        <IconSVG className="text-brand w-6 h-6" />
                    </div>
                )}
                <div className="ml-3 flex-1">
                    <div className="flex justify-between items-center">
                        <h3 className={`${focus !== id && 'truncate'} text-base font-semibold max-w-[215px] text-sm break-all`}>{fullTitle}</h3>
                        <p className="absolute right-4 top-[18px] text-xs text-gray-300 whitespace-nowrap flex items-center">
                            {date ? (
                                <>{!isSent ? '--:--' : buildDate(date)}</>
                            ) : (
                                <>{!customDate ? '--:--' : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</>
                            )
                            }
                            {(fullTitle.length > 30 || text.length > 65) && <FontAwesomeIcon icon={faChevronDown} className={`${focus === id && 'rotate-180'} ml-1 text-[10px]`} />}
                        </p>
                    </div>
                    <p className={`${focus !== id && 'line-clamp-2'} break-all text-sm text-gray-100 whitespace-pre-line word-break max-w-[260px] overflow-hidden`}>{text === '' ? '\n' : text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <MobileApp activeItem="mycard" showDefaultPage fullOverlay>
                <div className="absolute top-0 left-0 w-full h-full pt-7 bg-black/10 overflow-hidden">
                    <p className={`text-sm text-gray-300 bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md transition-all duration-500 ${title || content ? 'mt-[10px]' : '-mt-[210px]'}`}>{t('notification:preview')}.<br /><span className="text-xs text-gray-300">{t('notification:previewDisclaimer')}.</span></p>
                    {GenerateNotificationItem('preview', title, content, undefined, undefined, customDate)}

                    <p className="text-sm text-gray-300 bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md">{t('notification:lastNotifications')}.</p>
                    {lastNotifications.length > 0 ? (
                        lastNotifications.map((notification: Notification) => (GenerateNotificationItem(notification.id, notification.title, notification.text, new Date(notification.sentAt.date), notification.isSent)))
                    ) : (
                        <div className="bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md flex items-center">
                            <div className="bg-white p-0.5 rounded-full w-[40px] h-[40px] flex items-center justify-center scale-75">
                                <IconSVG className="text-brand w-6 h-6" />
                            </div>
                            <div className="ml-3 flex-1">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-base font-semibold truncate max-w-[225px] text-sm">{t('noResult')}</h3>
                                    <p className="text-xs text-gray-300 whitespace-nowrap">
                                        {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </p>
                                </div>
                                <p className="text-sm text-gray-100 whitespace-pre-line word-break custom-truncate max-w-[260px] overflow-hidden"></p>
                            </div>
                        </div>
                    )}
                </div>
            </MobileApp>
        </>
    );
};

export default NotifyHistory;
