import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { faFacebook, faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import LoadingPage from "../../Pages/LoadingPage";
import { OrganisationResponseItem } from "../../../Redux/Api/Request/Organisations";
import { useLazyGetOrganisationQuery } from "../../../Redux/Api/Organisations";
import { useAppSelector } from "../../../Redux/store";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface SocialsProps {
    customButton?: boolean
    customButtonText?: string[]
    customButtonUrl?: string[]
    customButtonIcon?: string[]
}

const Socials: React.FC<SocialsProps> = ({ customButton, customButtonText, customButtonUrl = [], customButtonIcon = [] }) => {
    const { t } = useTranslation();
    const [activeOrganisation, setActiveOrganisation] = useState<OrganisationResponseItem>();
    const [getOrganisation] = useLazyGetOrganisationQuery();
    const org_id = useAppSelector((s) => s.organisation.id);

    const getIcon = (icon: string) => {
        switch (icon) {
            case 'instagram':
                return faInstagram
            case 'facebook':
                return faFacebook
            case 'twitter':
                return faXTwitter
            case 'other':
                return faGlobe
            default:
                return faGlobe
        }
    }

    const getOrganisationName = (id: string) => {
        getOrganisation({ id }).then((data) => {
            setActiveOrganisation(data.data!);
        });
    };

    useEffect(() => {
        if (!org_id) return;
        getOrganisationName(org_id);
    }, [org_id]);

    if (!activeOrganisation) return <></>;

    return (
        <>
            <div className="flex flex-col justify-between h-full">
                <div className="p-[20px] pb-0">
                    <div className="font-app-bold text-[24px]">{activeOrganisation.name}</div>
                    <div className="text-[15px] text-gray-500">{t('cards:app:connectToDigipas', { organization: activeOrganisation.name })}</div>
                </div>
                <div className="p-6 flex flex-col min-h-[65%] max-h-[100%] gap-6 overflow-y-auto no-scrollbar">
                    {customButton ? (
                        <>

                            {customButtonText?.map((text, index) => (
                                <button onClick={() => window.open(customButtonUrl[index], '_blank')} key={index} className="font-app-bold py-4 rounded-[3px] relative bg-accent hover:bg-accent-light text-white text-[14px] w-full cursor-pointer">
                                    <FontAwesomeIcon icon={getIcon(customButtonIcon[index]) as IconProp} className="text-[27px] absolute top-1/2 -translate-y-1/2 right-[40px]" />
                                    {text ? text : '\u00a0'}
                                </button>
                            ))}
                        </>
                    ) : (
                        <>
                                <button className="py-4 rounded-[3px] relative bg-accent hover:bg-accent-light text-white text-[20px] w-full cursor-not-allowed font-app-bold">Instagram<FontAwesomeIcon icon={faInstagram as IconProp} className="text-[27px] absolute top-1/2 -translate-y-1/2 right-[40px]" /></button>
                                <button className="py-4 rounded-[3px] relative bg-accent hover:bg-accent-light text-white text-[20px] w-full cursor-not-allowed font-app-bold">Facebook<FontAwesomeIcon icon={faFacebook as IconProp} className="text-[27px] absolute top-1/2 -translate-y-1/2 right-[40px]" /></button>
                                <button className="py-4 rounded-[3px] relative bg-accent hover:bg-accent-light text-white text-[20px] w-full cursor-not-allowed font-app-bold">Digipas.app<FontAwesomeIcon icon={faGlobe as IconProp} className="text-[27px] absolute top-1/2 -translate-y-1/2 right-[40px]" /></button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Socials;
