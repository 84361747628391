import { useContext, useState } from 'react'
import Card from '../Card/Card'
import Button from '../Form/Button'
import Dropdown from '../Form/Dropdown'
import { ModalContext } from '../Modal/Modal'
import { TableContext } from './Table'

export interface TableSelectionHeaderProps {
    onAction: (list: Record<number, { index: number, data: any }>, action: string) => void,
    actions: Record<string, string>
    selectAll: () => void
}

const TableSelectionHeader = ({ onAction, actions, selectAll } : TableSelectionHeaderProps) => {

    const modalContext = useContext(ModalContext)
    const ctx = useContext(TableContext)

    const [ action, setAction ] = useState(Object.keys(actions)[0])

    const changeAction = (act: string) => {
        setAction(act)
    }

    const performAction = () => {
        if(onAction) onAction(ctx.selection, action)

        clearSelection()
    }

    const clearSelection = () => {
        ctx.setSelection({})
    }

    return (
        <Card className='flex my-4'>
            <div className='flex md:gap-4 justify-between flex-wrap'>
                <div className='flex gap-4 items-center'>
                    <span>Geselecteerd: <span className='text-accent'>{ Object.keys(ctx.selection).length }</span><span>&nbsp;/ { ctx.total }</span></span>
                    <Button action={ clearSelection } title='Selectie vrijmaken' />
                    <Button action={ selectAll } title='Alles selecteren' />
                </div>
                <div className={`flex md:gap-4 flex-wrap items-center ${ Object.keys(ctx.selection).length <= 0 && 'opacity-40 pointer-events-none' } `}>
                    <Dropdown initial={ Object.keys(actions)[0] } onChange={ changeAction } className='flex-grow w-56 translate-y-1' label='' options={ actions } />
                    <Button action={ modalContext.withModal({ title: `Weet je het zeker?`, body: `Weet je zeker dat je de actie "${ actions[action] }" wil uitvoeren op ${ Object.keys(ctx.selection).length } rijen?` }, performAction) } title='Actie uitvoeren' />
                </div>
            </div>
        </Card>
    )
}

export default TableSelectionHeader