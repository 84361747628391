import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Constants from '../../Constants';
import i18n from '../../Services/i18n/i18n';

function getLanguage() {
  return i18n.language || "nl";
}

const patrickAiApi = createApi({
  reducerPath: 'patrickAiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: Constants.PATRICKAI_API_URL,
    prepareHeaders: (headers) => {
      headers.set('PAI-API-KEY', Constants.PATRICKAI_API_KEY);
      headers.set('Accept-Language', getLanguage()); 
      return headers;
    },
  }),
  endpoints: (build) => ({
    fetchCsvData: build.mutation({
      query: (org_id: string) => ({
        url: `/digipas/processedfile/${org_id}`,
        method: 'GET',
      }),
    }),

    checkIsUploadedFile: build.mutation({
      query: (org_id: string) => ({
        url: `/digipas/uploadedfile/${org_id}`,
        method: 'GET',
      }),
    }),

    downloadProcessedFile: build.mutation<Blob, string>({
      query: (org_id: string) => ({
        url: `/digipas/processedfile/${encodeURIComponent(org_id)}/download`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob();
          return blob
        },        
        cache: "no-cache", 
      }),
    }),
    

    scanForErrors: build.mutation({
      query: (org_id: string) => {
        const formData = new FormData();
        formData.append('organisation_id', org_id);

        return {
          url: '/digipas/validatefile',
          method: 'POST',
          body: formData,
        };
      },
    }),

    uploadCsvFile: build.mutation({
      query: ({ org_id, file }: { org_id: string; file: Blob }) => {
        const formData = new FormData();
        formData.append('organisation_id', org_id);
        formData.append('file', file);

        return {
          url: '/digipas/upload/processedfile',
          method: 'POST',
          body: formData,
        };
      },
    }),

    uploadFile: build.mutation({
      query: ({ org_id, file }: { org_id: string; file: File }) => {
        const formData = new FormData();
        formData.append('organisation_id', org_id);
        formData.append('file', file);

        return {
          url: '/digipas/uploadfile',
          method: 'POST',
          body: formData,
        };
      },
    }),

    handleExtractFields: build.mutation({
      query: (org_id: string) => ({
        url: '/digipas/extractfields',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organisation_id: org_id }),
      }),
    }),

    handleDisplayError: build.mutation({
      query: ({ org_id, mapped, is_checked }: { org_id: string; mapped: any; is_checked: boolean }) => {
        const formData = new FormData();
        formData.append('mapped', JSON.stringify({ mapped }));
        formData.append('is_checked', is_checked.toString());
        formData.append('organisation_id', org_id);

        return {
          url: '/digipas/processfile',
          method: 'POST',
          body: formData,
        };
      },
    }),

    chatMessage: build.mutation({
      query: ({ human_message, conversation_history } : {human_message : string, conversation_history : string[][]}) => ({
        url: `/digipas/chat`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify({
          human_message: human_message,
          conversation_history : conversation_history,
        }),
      }),
    }),
    

    verifyTemplate: build.mutation({
      query: (org_id: string) => ({
        url: `/digipas/verify_template_file/${org_id}`,
        method: 'POST',
      }),
    }),

    checkHealth: build.mutation<void, void>({
        query: () => ({
          url: `/digipas/check_health`,
          method: 'GET',
        }),
      }),

  }),
});

export const {
  useFetchCsvDataMutation,
  useDownloadProcessedFileMutation,
  useCheckIsUploadedFileMutation,
  useScanForErrorsMutation,
  useUploadCsvFileMutation,
  useHandleExtractFieldsMutation,
  useHandleDisplayErrorMutation,
  useChatMessageMutation,
  useVerifyTemplateMutation,
  useUploadFileMutation,
  useCheckHealthMutation
} = patrickAiApi;

export default patrickAiApi;
