import { faPaperPlane, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import React, { useContext, useState, useEffect } from 'react'
import { useLazyGetOrganisationTypesQuery, usePostNotificationMutation } from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import { all, max, min } from '../../../../Utils/InValidation'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import Dropdown from "../../../Shared/Form/Dropdown";
import LoadingPage from "../../LoadingPage";
import { useTranslation } from "react-i18next";
import NotifyPreview from "./NotifyPreview";
import { useNavigate, useLocation } from 'react-router-dom';
import CalendarInput from '../../../Shared/Form/CalendarInput';
import HourInput from '../../../Shared/Form/HourInput';
import { JWT } from '../../../../Utils/JWT';
import Card from '../../../Shared/Card/Card';

const NotifyPage = () => {

    const modalContext = useContext(ModalContext)
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const org_id = useAppSelector((s) => s.organisation.id)!
    const [getTypes, { data: types }] = useLazyGetOrganisationTypesQuery()

    const [error, setError] = useState('')
    const [errorSubtitle, setErrorSubtitle] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [busy, setBusy] = useState(false)
    const [sendNotifications] = usePostNotificationMutation()
    const [message, setMessage] = useState('')
    const [sendType, setSendType] = useState(true)
    const [sendTime, setSendTime] = useState(true)
    const [refetch, setRefetch] = useState(false)

    const roles = JWT.read<Array<string>>('roles') || [];
    const isAdmin = roles.includes('ROLE_SUPER_ADMIN');
    const [date, setDate] = useState(new Date());

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const { withModal } = useContext(ModalContext);

    const location = useLocation();
    const { news } = location.state || {};

    const toMySQLDate = (date: string, time: string): string => {
        const parsedDate = new Date(date);

        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const day = String(parsedDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day} ${time}`;
    }

    useEffect(() => {
        if (news) {
            setTitle(news.title)
            setContent(cleanData(news.text))
            news.type && setSendType(news.type !== 'all' ? false : true)
        }
    }, [news])

    useEffect(() => {
        if (org_id)
            getTypes(org_id)
    }, [org_id])

    const handleSendType = () => {
        // @ts-ignore
        if (types.length > 0) {
            setSendType(!sendType)
        } else {
            setError(t('modal:noTypes') as string)
            setErrorSubtitle('\n')
        }
    }

    const handleSendTime = () => {
        setSendTime(!sendTime)
    }

    const submit = async (data: { title: string, content: string, url: string, cardType: string, scheduledAt: string, scheduledAtTime: string }) => {
        setSubmitted(true)
        setError('')
        setErrorSubtitle('')
        if (busy) return
        if (FailedRequirements(data, 'title', 'content')) return setError(t('modal:error') as string)
        if (data.scheduledAt && data.scheduledAtTime.length !== 5) return setError(t('modal:error') as string)

        if (data.url !== "") {
            if (!data.url.toLowerCase().includes('http')) {
                setError(t('modal:urlError') as string)
                setBusy(false)
                return;
            }
        }

        if (!(await modalContext.show(t('modal:attention') as string, t('modal:notification') as string))) {
            modalContext.hide()
            return
        }

        modalContext.hide()

        setBusy(true)
        setError('')
        setMessage('')
        

        try {
            await sendNotifications({
                id: org_id,
                title: data.title,
                body: data.content,
                url: data.url,
                type: data.cardType,
                scheduledAt: data.scheduledAt ? toMySQLDate(data.scheduledAt, data.scheduledAtTime) : null
            })

            if (data.cardType === 'all') {
                const sentMessage = t('notification:sent');
                setMessage(sentMessage);
            } else {
                const sentMessage = t('notification:sentType');
                setMessage(sentMessage);
            }

            setTitle('');
            setContent('');
            setRefetch(!refetch);

        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setBusy(false)
    }

    const cleanData = (data: string) => {
        return data.replace(/<\/(h1|h2|h3|h4|h5|h6|p|div|li)>/gi, '\n').replace(/<\/?[^>]+(>|$)/g, '');
    }

    if (!types) return <LoadingPage />

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col xl:flex-row'>
                    <div className='w-full'>
                        <Title text={t('notification:title')} subtitle={t('notification:subtitle') as string} />
                        <StatusPopups setText={setError} setSubtitle={setErrorSubtitle} type='error' text={error} subtitle={errorSubtitle} />
                        <StatusPopups setText={setMessage} type='success' text={message} />
                        <Form submit={submit}>
                            <Input
                                required
                                submitted={submitted ? true : false}
                                invalidator={all(min(3), max(100))}
                                label={t('notification:tableTitle')}
                                id='title'
                                onChange={(e) => setTitle((e.target as HTMLInputElement).value)}
                                initial={news ? news.title : ''}
                            />

                            {isAdmin ? (
                                <>
                                    <p className='mb-1'>{t('notification:specialCases')}</p>
                                    <Card outline rounded className='mb-6'>
                                        <div>
                                            <input onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); e.key === 'Enter' && handleSendType(); }} type='checkbox' className='ml-1 h-4 w-4 mb-4 cursor-pointer' checked={sendType} onChange={handleSendType} />
                                            <span className={`${!sendType && 'line-through'} ml-1 opacity-70 text-xl`}> {t('notification:checkbox')}&nbsp;</span>
                                        </div>
                                        {sendType ? (
                                            <input type='hidden' value='all' name='cardType' />
                                        ) : (
                                            <Dropdown noMarginBottom className='mt-2 mb-2' label={t('notification:type')} initial={news ? news.type : ''} id='cardType' options={
                                                Object.fromEntries(types.map(type => ([type.id, type.name])))
                                            } />
                                        )}


                                        <div className='border-t-[1px] border-light-250 pt-2'>
                                            <input onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); e.key === 'Enter' && handleSendTime(); }} type='checkbox' className='ml-1 h-4 w-4 cursor-pointer' checked={sendTime} onChange={handleSendTime} />
                                            <span className={`${!sendTime && 'line-through'} ml-1 opacity-70 text-xl`}> {t('notification:checkboxTime')}&nbsp;</span>
                                        </div>
                                        {!sendTime && (
                                            <div className='mt-6'>
                                                <CalendarInput 
                                                    required 
                                                    submitted={submitted ? true : false} 
                                                    id='scheduledAt' 
                                                    label={t('notification:sendAtDate')} 
                                                    className='mb-6' 
                                                    min={new Date(new Date().toISOString().split('T')[0])}
                                                    setDate={setDate}
                                                />
                                                <HourInput 
                                                    required 
                                                    submitted={submitted ? true : false} 
                                                    id='scheduledAtTime' 
                                                    label={t('notification:sendAtTime')} 
                                                    isToday={date}
                                                />
                                            </div>
                                        )}
                                    </Card>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <input onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); e.key === 'Enter' && handleSendType(); }} type='checkbox' className='ml-1 h-4 w-4 mb-6 cursor-pointer' checked={sendType} onChange={handleSendType} />
                                        <span className={`${!sendType && 'line-through'} ml-1 opacity-70 text-xl`}> {t('notification:checkbox')}&nbsp;</span>
                                    </div>
                                    {sendType ? (
                                        <input type='hidden' value='all' name='cardType' />
                                    ) : (
                                        <Dropdown noMarginBottom className='mb-4' label={t('notification:type')} initial={news ? news.type : ''} id='cardType' options={
                                            Object.fromEntries(types.map(type => ([type.id, type.name])))
                                        } />
                                    )}</>
                            )}

                            <Input
                                required
                                submitted={submitted ? true : false}
                                large
                                invalidator={all(min(3), max(250))}
                                label={t('notification:message')}
                                id='content'
                                onChange={(e) => setContent((e.target as HTMLInputElement).value)}
                                initial={news ? cleanData(news.text) : ''}
                            />

                            <Input
                                invalidator={all(min(3), max(250))}
                                label={t('notification:url')}
                                sublabel={t('notification:optional') as string}
                                type='url'
                                placeholder='https://'
                                id='url'
                                initial={news ? news.url : ''}
                            />

                            <div className='flex justify-between flex-col sm:flex-row gap-4'>
                                <Button title={t('notification:send')} iconright icon={faPaperPlane} />
                                <Button
                                    title={t('notification:history')}
                                    className={title || content ? 'opacity-75' : ''}
                                    iconright
                                    icon={faArrowRight}
                                    nosubmit
                                    action={
                                        title || content
                                            ? withModal(
                                                { title: t('notification:goToHistory'), body: t('notification:goToHistoryBody') as string },
                                                () => navigate('/' + i18n.language + '/dashboard/notify/history')
                                            )
                                            : () => navigate('/' + i18n.language + '/dashboard/notify/history')
                                    }
                                />
                            </div>
                        </Form>
                    </div>
                    <div className='-m-32 sm:m-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 flex justify-center xl:justify-end mb-0 sm:mb-10'>
                        {/* @ts-ignore */}
                        <NotifyPreview key={refetch} title={title} content={content} customDate={sendTime} />
                    </div>
                </div>
            </BusyArea>
        </>
    )
}

export default NotifyPage;
