import { useTranslation } from "react-i18next";
import bot from "../../../Assets/patrick.png";
import paperclip from "../../../Assets/paperclip.svg";
import santa from "../../../Assets/santa.png";
import { JWT } from "../../../Utils/JWT";
import Constants from "../../../Constants";

interface Message {
  sender: string;
  content: string;
  time: string;
}

interface Messages extends Array<Message> {}

interface ChatboxMessagesProps {
  messages: any;
  handleButtonClick: () => void;
  isOnline: boolean;
  first_name: string;
  last_name: string;
  isAwaitingResponse: boolean;
  isChatOpen: boolean;
}

const ChatboxMessages: React.FC<ChatboxMessagesProps> = ({
  messages,
  handleButtonClick,
  isOnline,
  first_name,
  last_name,
  isAwaitingResponse,
  isChatOpen,
}) => {
  const { t, i18n } = useTranslation();
  const today = new Date();
  const isWinter =
    today.getMonth() === 11 || (today.getMonth() === 0 && today.getDate() <= 5);
  const isSocialUser =
    JWT.read<Array<string>>("roles")?.includes("ROLE_SOCIAL");

  const findValidMessage = (
    messages: Messages,
    index: number,
    direction: "previous" | "next"
  ): Message | null => {
    let currentIndex = direction === "previous" ? index - 1 : index + 1;

    while (currentIndex >= 0 && currentIndex < messages.length) {
      const currentMessage = messages[currentIndex];
      if (
        currentMessage.sender !== "assistant_fail" &&
        !(
          currentMessage.content.includes("busy : true") &&
          /\((de|nl|fr|es|en)\)/i.test(currentMessage.content)
        )
      ) {
        return currentMessage;
      }
      currentIndex += direction === "previous" ? -1 : 1;
    }

    return null;
  };

  return messages.map((msg: Message, index: number) => {
    const prevMessage = findValidMessage(messages, index, "previous");
    const isNewSender = !prevMessage || prevMessage.sender !== msg.sender;
    const nextMessage = findValidMessage(messages, index, "next");
    const isLastInSequence = !nextMessage || nextMessage.sender !== msg.sender;

    const languageRegex = /\((\w{2})\)(?=[^\w]*$)/;
    const match = msg.content.match(languageRegex);
    const messageLanguage = match
      ? match[1].toLowerCase()
      : i18n.language.toLowerCase();
    const messageContent =
      msg.sender === "assistant"
        ? msg.content
            .replace(languageRegex, "")
            .replace(/\s+upload\s*:\s*true/g, "")
            .replace(/\s+Upload\s*:\s*true/g, "")
            .replace(/\s+upload\s*:\s*false/g, "")
            .trim()
        : msg.content.replace(languageRegex, "").trim();

    const regex =
      /(\*\*[^*]+\*\*|\*[^*]+\*|_[^_]+_|`[^`]+`|~~[^~]+~~|``[^`]+``|```[^`]+```)/g;
    let idx = 0;

    const parseToken = (token: string) => {
      if (token.startsWith("**") && token.endsWith("**")) {
        return <b key={idx++}>{parseToken(token.slice(2, -2))}</b>;
      } else if (
        (token.startsWith("*") && token.endsWith("*")) ||
        (token.startsWith("_") && token.endsWith("_"))
      ) {
        return <i key={idx++}>{parseToken(token.slice(1, -1))}</i>;
      } else if (token.startsWith("```") && token.endsWith("```")) {
        return (
          <code className="large-code" key={idx++}>
            {token.slice(4, -3)}
          </code>
        );
      } else if (token.startsWith("``") && token.endsWith("``")) {
        return (
          <code className="large-code" key={idx++}>
            {token.slice(2, -2)}
          </code>
        );
      } else if (token.startsWith("`") && token.endsWith("`")) {
        return (
          <code className="normal-code" key={idx++}>
            {token.slice(1, -1)}
          </code>
        );
      } else if (token.startsWith("~~") && token.endsWith("~~")) {
        return <del key={idx++}>{parseToken(token.slice(2, -2))}</del>;
      } else {
        return token;
      }
    };

    const tokens = messageContent.split(regex);

    const formattedMessageContent = tokens.map((token) => {
      return parseToken(token);
    });

    // Try to get the initials of the user by getting the first capital letter so that we skip the infix
    // Previeuw: "de Bierman" -> "B"
    // In case of a name without a capital letter, it gets the first letter of the name
    const getInitials = (name: string) => {
      const capitalizedLetter = name.match(/[A-Z]/);
      return capitalizedLetter ? capitalizedLetter[0] : name[0];
    };

    // Get the display name that will be shown as the sender's profile picture
    const getDisplayName = () => {
      let displayName;

      if (first_name && last_name) {
        displayName = getInitials(first_name) + getInitials(last_name);
      } else {
        displayName = t("patrick:you");
      }

      return displayName;
    };

    if (msg.sender === "assistant_fail") return;

    return (
      <>
        <div
          key={`${msg.time}-${index}`}
          className={
            msg.sender === "assistant" &&
            messageContent === "busy : true" &&
            !isLastInSequence
              ? ""
              : "mb-1.5"
          }
        >
          {/* icon for the upload button if needed */}
          {msg.sender === "assistant" && messageContent === "upload : true" ? (
            <>
              {isNewSender ? (
                <div className="-mb-[50px] relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden">
                  <img
                    src={bot}
                    alt="Patrick assistance"
                    className="absolute top-0 left-0 w-[70px] mt-[4px]"
                  />
                  {isWinter && (
                    <img
                      src={santa}
                      alt="Hat"
                      className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`}
                    />
                  )}
                </div>
              ) : (
                <div className="-mt-1.5"></div>
              )}
            </>
          ) : // start mapping prompt + button
          msg.sender === "assistant" &&
            (messageContent === "gotobuttons : true" ||
              messageContent === "relocate : true") ? (
            <>
              <div
                className={`flex justify-start ${!isNewSender ? "pl-10" : ""}`}
              >
                {isNewSender && (
                  <div className="relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden">
                    <img
                      src={bot}
                      alt="Patrick assistance"
                      className="absolute top-0 left-0 w-[70px] mt-[4px]"
                    />
                    {isWinter && (
                      <img
                        src={santa}
                        alt="Hat"
                        className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`}
                      />
                    )}
                  </div>
                )}

                {/* look if user is allowed to import */}
                {!isSocialUser ? (
                  <div
                    className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}
                  >
                    {t("patrick:importPrompt", { lng: messageLanguage })}
                    <button
                      className="bg-[#3273f6] text-white rounded-[10px] px-3 py-2 text-xs w-[100%] my-1 disabled:bg-[#7e7e7e]"
                      onClick={() => handleButtonClick()}
                      disabled={messageContent === "relocate : true"}
                    >
                      {t("patrick:mapping", { lng: messageLanguage })}
                    </button>
                  </div>
                ) : (
                  <div
                    className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}
                  >
                    {t("patrick:notAllowed", { lng: messageLanguage })}
                  </div>
                )}
              </div>
            </>
          ) : // download prompt
          msg.sender === "assistant" && messageContent === "download : true" ? (
            <>
              <div
                className={`flex justify-start ${!isNewSender ? "pl-10" : ""}`}
              >
                {isNewSender && (
                  <div className="relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden">
                    <img
                      src={bot}
                      alt="Patrick assistance"
                      className="absolute top-0 left-0 w-[70px] mt-[4px]"
                    />
                    {isWinter && (
                      <img
                        src={santa}
                        alt="Hat"
                        className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`}
                      />
                    )}
                  </div>
                )}
                <div
                  className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}
                >
                  {t("patrick:downloadPrompt", { lng: messageLanguage })}{" "}
                  <a
                    className="my-2 text-[#3273f6] hover:underline"
                    href={Constants.DOWNLOAD_URL_CARDS_TEMPLATE}
                    download
                  >
                    {t("patrick:downloadPromptSecondary", {
                      lng: messageLanguage,
                    })}
                  </a>
                  .
                </div>
              </div>
            </>
          ) : // loader message from text
          msg.sender === "assistant" && messageContent === "busy : true" ? (
            isLastInSequence ? (
              <div key={`${msg.time}-${index}-load`} className="mt-1.5 mb-1.5">
                <div className={`flex justify-start mt-2.5`}>
                  <div className="relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden">
                    <img
                      src={bot}
                      alt="Patrick assistance"
                      className="absolute top-0 left-0 w-[70px] mt-[4px]"
                    />
                    {isWinter && (
                      <img
                        src={santa}
                        alt="Hat"
                        className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`}
                      />
                    )}
                  </div>
                  <div
                    className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] break-words h-[26px] whitespace-pre-wrap ${
                      [".csv", ".xls", ".xlsx"].some((ext) =>
                        msg.content.toLocaleLowerCase().includes(ext)
                      )
                        ? `busy`
                        : "loader "
                    }`}
                  >
                    <p className="opacity-0">
                      {[".csv", ".xls", ".xlsx"].some((ext) =>
                        msg.content.toLocaleLowerCase().includes(ext)
                      )
                        ? t("patrick:busy")
                        : t("patrick:typingLoader")}
                      ...
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : (
            // normal message
            <div
              className={`flex ${
                msg.sender === "assistant" ? "justify-start" : "justify-end"
              } ${msg.sender === "assistant" && !isNewSender ? "pl-10" : ""} ${
                msg.sender === "human" && !isNewSender ? "pr-11" : ""
              }`}
            >
              {isNewSender && msg.sender === "assistant" && (
                <div className="relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden">
                  <img
                    src={bot}
                    alt="Patrick assistance"
                    className="absolute top-0 left-0 w-[70px] mt-[4px]"
                  />
                  {isWinter && (
                    <img
                      src={santa}
                      alt="Hat"
                      className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`}
                    />
                  )}
                </div>
              )}
              <div
                className={`inline-block p-4 ${
                  msg.sender === "assistant" ? "border" : "bg-[#F5F8FB]"
                } border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}
              >
                {msg.sender === "assistant" &&
                messageContent === "start message" ? (
                  isOnline ? (
                    t("patrick:startPrompt", { lng: i18n.language })
                  ) : (
                    <>
                      {t("patrick:offlinePrompt", { lng: i18n.language })}{" "}
                      <a
                        className="text-[#3273f6] hover:underline"
                        tabIndex={isChatOpen ? 0 : -1}
                        href="mailto: support@digipas.app"
                      >
                        support@digipas.app
                      </a>
                      .
                    </>
                  )
                ) : msg.sender === "assistant" &&
                  messageContent === "offline : true" ? (
                  <>
                    {t("patrick:offlinePrompt", { lng: messageLanguage })}{" "}
                    <a
                      className="text-[#3273f6] hover:underline"
                      tabIndex={isChatOpen ? 0 : -1}
                      href="mailto: support@digipas.app"
                    >
                      support@digipas.app
                    </a>
                    .
                  </>
                ) : msg.sender === "assistant" ? (
                  formattedMessageContent
                ) : (
                  messageContent
                )}
                {msg.sender === "assistant" &&
                  msg.content.toLocaleLowerCase().includes("upload : true") &&
                  "\n\n" +
                    t("patrick:importingSuggestionPrompt", {
                      lng: messageLanguage,
                    })}
              </div>

              {msg.sender === "human" && isNewSender && (
                <div className="w-9 h-9 ml-1.5 mt-2 bg-[#3273f6] text-white flex items-center justify-center text-xs rounded-full">
                  {getDisplayName()}
                </div>
              )}
            </div>
          )}

          {/* upload button */}
          {msg.sender === "assistant" &&
            (msg.content.toLocaleLowerCase().includes("upload : true") ||
              msg.content.includes("upload : false")) && (
              <div className={`flex justify-start mt-1.5`}>
                <div className="pl-10"></div>

                {/* look if user is allowed to import */}
                {!isSocialUser ? (
                  <div className="max-w-[400px] p-[6px] pl-[16px] border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] flex flex-row items-center gap-[5px]">
                    {t("patrick:fileUploadPrompt", { lng: messageLanguage })}
                    <label
                      htmlFor={isOnline ? "csvFileInput" : undefined}
                      className={
                        isOnline ? "cursor-pointer" : "cursor-not-allowed"
                      }
                    >
                      <div
                        className={`${
                          isOnline && "shake"
                        } max-w-[400px] bg-[#F5F8FB] rounded-[25px] text-[#5D6C78] flex flex-row items-center gap-[5px] p-[10px]`}
                      >
                        <img
                          src={paperclip}
                          alt="File upload"
                          className="shake-item w-2.5 h-4"
                        />
                        {t("patrick:upload", { lng: messageLanguage })}
                      </div>
                    </label>
                  </div>
                ) : (
                  <div
                    className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}
                  >
                    {t("patrick:notAllowed", { lng: messageLanguage })}
                  </div>
                )}
              </div>
            )}

          {/* Time indicator on last message */}
          {isLastInSequence && (
            <div
              className={`text-[#B6BFC7] flex ${
                msg.sender === "assistant"
                  ? "justify-start pl-16"
                  : "justify-end pr-16"
              } text-xs mb-2.5`}
            >
              {msg.time}
            </div>
          )}
        </div>

        {/* Loader if we are waiting for the AI */}
        {isLastInSequence && !nextMessage && isAwaitingResponse && (
          <div key={`${msg.time}-${index}-load`} className="mb-1.5">
            <div className={`flex justify-start mt-2.5`}>
              <div className="relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden">
                <img
                  src={bot}
                  alt="Patrick assistance"
                  className="absolute top-0 left-0 w-[70px] mt-[4px]"
                />
                {isWinter && (
                  <img
                    src={santa}
                    alt="Hat"
                    className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`}
                  />
                )}
              </div>
              <div
                className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] break-words h-[26px] whitespace-pre-wrap ${
                  [".csv", ".xls", ".xlsx"].some((ext) =>
                    msg.content.toLocaleLowerCase().includes(ext)
                  )
                    ? `busy`
                    : "loader "
                }`}
              >
                <p className="opacity-0">
                  {[".csv", ".xls", ".xlsx"].some((ext) =>
                    msg.content.toLocaleLowerCase().includes(ext)
                  )
                    ? t("patrick:busy")
                    : t("patrick:typingLoader")}
                  ...
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  });
};

export default ChatboxMessages;
