import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { useChat } from './ChatContext';
import bot from '../../../Assets/patrick.png';
import close from '../../../Assets/close.svg';
import ellipse from '../../../Assets/ellipse.svg';
import ellipseRed from '../../../Assets/ellipseRed.svg';
import wave from '../../../Assets/wave.svg';
import chat from '../../../Assets/chat.svg';
import paperclip from '../../../Assets/paperclip.svg';
import send from '../../../Assets/send.svg';
import IconSVG from '../../../Assets/icon.svg?component';
import santa from '../../../Assets/santa.png';
import ChatboxSuggestions from './ChatboxSuggestions';
import ChatboxMessages from './ChatboxMessages';
import ChatBoxAnimations from './ChatboxAnimations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion'
import { useAppSelector } from '../../../Redux/store';
import { useChatMessageMutation, useUploadFileMutation, useVerifyTemplateMutation, useCheckHealthMutation } from '../../../Redux/Api/PatrickAiApi';
import { JWT } from '../../../Utils/JWT';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const ChatBox: React.FC = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { isChatOpen, toggleChat, messages, addMessage, setMessages } = useChat();
    const [message, setMessage] = useState('');
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const [introVisible, setIntroVisible] = useState(() => {
        const storedValue = localStorage.getItem('introVisible');
        return storedValue ? JSON.parse(storedValue) : true;
    });
    const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(() => {
        const storedValue = localStorage.getItem('showSuggestions');
        return storedValue ? JSON.parse(storedValue) : true;
    });
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const [firstOpen, setfirstOpen] = useState(false);
    const [isCooldown, setIsCooldown] = useState(false);
    const [isWaveCooldown, setIsWaveCooldown] = useState(false);
    const [firstOpenIntro, setfirstOpenIntro] = useState(false);
    const [isCooldownIntro, setIsCooldownIntro] = useState(false);
    const isInputNotEmpty = message.trim().length > 0;
    const [atBottom, setAtBottom] = useState(false);
    const [canScroll, setCanScroll] = useState(false);
    const [chatMessage] = useChatMessageMutation();
    const [verifyTemplate] = useVerifyTemplateMutation();
    const [checkHealth] = useCheckHealthMutation();
    const BOTTOM_THRESHOLD = 200;
    const [uploadFile] = useUploadFileMutation();
    const first_name = JWT.read<string>('given_name') || '';
    const last_name = JWT.read<string>('family_name') || '';
    const full_name = first_name + ' ' + last_name;
    const user_email = JWT.read<string>('username');

    const [isOnline, setIsOnline] = useState(true);

    const today = new Date();
    const isWinter = (today.getMonth() === 11) ||
        (today.getMonth() === 0 && today.getDate() <= 5);

    if (sessionStorage.getItem('setImportComplete') === 'true') {
        const newMessage = {
            sender: 'assistant',
            content: t('mapping:successPrompt') + "(" + i18n.language + ")",
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        };

        setIsAwaitingResponse(true);
        setTimeout(() => {
            setIsAwaitingResponse(false);
            addMessage(newMessage);
        }, 1000);

        sessionStorage.removeItem('setImportComplete');
    }

    const introToggle = () => {
        if (isCooldownIntro) return
        setIsCooldownIntro(true);
        setIntroVisible((prevValue: boolean) => {
            const newValue = !prevValue;
            localStorage.setItem('introVisible', JSON.stringify(newValue));
            return newValue;
        });
        setfirstOpenIntro(true);
        setTimeout(() => {
            setIsCooldownIntro(false);
        }, 300);
    }

    const openChat = () => {
        if (isCooldown) return
        toggleChat();
        setfirstOpen(true);
        setIsCooldown(true);
        setIsWaveCooldown(true);
        setTimeout(() => {
            setIsCooldown(false);
        }, 400);
        setTimeout(() => {
            setIsWaveCooldown(false);
        }, 500);
    }

    useEffect(() => {
        checkHealthStatus();
    }, [])

    useEffect(() => {
        localStorage.setItem('introVisible', JSON.stringify(introVisible));
    }, [introVisible]);

    useEffect(() => {
        const savedMessages = sessionStorage.getItem('chatMessages');
        if (savedMessages) {
            setMessages(JSON.parse(savedMessages));
        } else {
            const initialMessage = {
                sender: 'assistant',
                content: `start message`,
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };
            setMessages([initialMessage]);
        }
    }, [location.pathname, setMessages]);

    useEffect(() => {
        if (messages.length > 0) {
            sessionStorage.setItem('chatMessages', JSON.stringify(messages));
        }
    }, [messages]);

    const checkHealthStatus = async () => {
        try {
            await checkHealth().unwrap();
            setIsOnline(true)
        } catch (error) {
            const fetchError = error as FetchBaseQueryError;
            if (fetchError.status !== 200) setIsOnline(false)
        };
    }

    const getPathName = (pathCode: string): string => {
        const sanitizedPath = pathCode.endsWith("/") && pathCode.length > 1 ? pathCode.slice(0, -1) : pathCode;
        const normalizedPath = sanitizedPath.replace(/\/[a-f0-9\-]{36}$/, '/*');

        const pageMap: { [key: string]: string } = {
            [`/${i18n.language}/dashboard`]: 'dashboard',
            [`/${i18n.language}/dashboard/success`]: 'digipas activated',
            [`/${i18n.language}/dashboard/cards/list`]: 'card overview',
            [`/${i18n.language}/dashboard/cards/edit/*`]: 'card edit',
            [`/${i18n.language}/dashboard/cards/create`]: 'card create',
            [`/${i18n.language}/dashboard/cards/import`]: 'import cards',
            [`/${i18n.language}/dashboard/organisation/appbuilder`]: 'appbuilder',
            [`/${i18n.language}/dashboard/organisation/theme`]: 'theme',
            [`/${i18n.language}/dashboard/organisation/socials`]: 'socials',
            [`/${i18n.language}/dashboard/news/list`]: 'news',
            [`/${i18n.language}/dashboard/news/create`]: 'create news',
            [`/${i18n.language}/dashboard/news/edit/*`]: 'edit news',
            [`/${i18n.language}/dashboard/notify`]: 'notification',
            [`/${i18n.language}/dashboard/notify/history`]: 'notification history',
            [`/${i18n.language}/dashboard/users/list`]: 'users overview',
            [`/${i18n.language}/dashboard/users/edit/*`]: 'user edit',
            [`/${i18n.language}/dashboard/users/create`]: 'user create',
            [`/${i18n.language}/dashboard/sponsor/list`]: 'sponsor overview',
            [`/${i18n.language}/dashboard/sponsor/create`]: 'sponsor create',
            [`/${i18n.language}/dashboard/sponsor/edit/*`]: 'sponsor edit',
            [`/${i18n.language}/dashboard/admin/organisation/list`]: 'adminpage organisations overview',
            [`/${i18n.language}/dashboard/admin/organisation/edit/*`]: 'adminpage organisation edit',
            [`/${i18n.language}/dashboard/admin/organisation/create`]: 'adminpage organisation create',
            [`/${i18n.language}/dashboard/admin/suggestion/list`]: 'adminpage suggestion overview',
            [`/${i18n.language}/dashboard/admin/suggestion/edit/*`]: 'adminpage suggestion edit',
            [`/${i18n.language}/dashboard/admin/suggestion/create`]: 'adminpage suggestion create',
            [`/${i18n.language}/dashboard/admin/notes/list`]: 'adminpage release notes overview',
            [`/${i18n.language}/dashboard/admin/notes/edit/*`]: 'adminpage release note edit',
            [`/${i18n.language}/dashboard/admin/notes/create`]: 'adminpage release note create',
        };

        return pageMap[normalizedPath] || 'unknown';
    };


    const handleSendMessage = (msg?: string) => {
        const content = msg || message.trim();
        if (content) {
            const newMessage = {
                sender: 'human',
                content: content + "(" + i18n.language + ")",
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };
            addMessage(newMessage);
            setMessage('');
            if (isOnline) setIsAwaitingResponse(true);

            const buildConversationHistory = () => {
                return messages.map((msg) => [msg.sender, msg.content]);
            };

            const sendMessage = async () => {
                if (content.toLowerCase() === '/c' || content.toLowerCase() === '/clear' || content.toLowerCase() === 'clear chat') {
                    setMessages([
                        {
                            sender: 'assistant',
                            content: `start message`,
                            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        },
                    ]);
                    sessionStorage.removeItem('chatMessages');
                    setIsAwaitingResponse(false);
                    return;
                }

                if (content.toLowerCase() === '/debug') {
                    setIsAwaitingResponse(false);
                    const timestamp = new Date().toLocaleString();
                    const messageLog = messages.map(msg => `Sender: ${msg.sender}\nContent: ${msg.content}\nTime: ${msg.time}`).join('\n\n---\n\n');
                    const messageData = `Patrick Assistant Debug Log\nCreated at: ${timestamp}\n\nUser Email: ${user_email}\nUser Full Name: ${full_name}\n\nPortal Language: ${i18n.language}\nPortal URL: ${window.location.href}\nPortal Clean Location: ${getPathName(location.pathname)} page\n\n\nMessages:\n\n${messageLog}`;
                    const blob = new Blob([messageData], { type: 'text/plain;charset=utf-8' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'DigiChat_Debug.txt';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return;
                }

                if (!isOnline) {
                    const GPTmessage = {
                        sender: 'assistant',
                        content: 'offline : true(' + i18n.language + ')',
                        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    };
                    addMessage(GPTmessage);
                    return;
                };

                try {
                    const human_message = content + ' Location: ' + getPathName(location.pathname) + ' page';
                    const conversation_history = buildConversationHistory();
                    const data = await chatMessage({ human_message, conversation_history }).unwrap();
                    if (data) {
                        const GPTmessage = {
                            sender: 'assistant',
                            content: data.response,
                            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        };
                        addMessage(GPTmessage);
                        setIsAwaitingResponse(false);
                    }
                } catch (error) {
                    const GPTmessage = {
                        sender: 'assistant_fail',
                        content: 'Error sending message: ' + JSON.stringify(error),
                        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    };
                    addMessage(GPTmessage);
                    checkHealthStatus();
                    setIsAwaitingResponse(false);
                }
            };
            sendMessage();
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        const translatedSuggestion = t(suggestion);
        handleSendMessage(translatedSuggestion);
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const validExtensions = ['csv', 'xls', 'xlsx'];
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !validExtensions.includes(fileExtension)) {
                return;
            }

            const updatedMessages = messages.map(msg => {
                const languageRegex = /\((\w{2})\)$/;
                const messageContent = msg.content.replace(languageRegex, '').trim();
                const previousLanguage = msg.content.match(languageRegex)?.[1];
                if (messageContent === "gotobuttons : true") {
                    return { ...msg, content: "relocate : true(" + previousLanguage + ")" };
                }
                return msg;
            });
            setMessages(updatedMessages);

            setIsAwaitingResponse(true);
            const newMessage = {
                sender: 'human',
                content: `${t("patrick:uploadedFile")} ${file.name}`,
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };

            addMessage(newMessage);
            try {
                const data = await uploadFile({ org_id, file }).unwrap()
                if (data) {
                    setTimeout(() => {
                        const followUpMessage = {
                            sender: 'assistant',
                            content: "gotobuttons : true(" + i18n.language + ")",
                            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        };
                        addMessage(followUpMessage);
                        setIsAwaitingResponse(false);
                    }, Math.floor(Math.random() * (2000 - 1100 + 1)) + 1100);
                }
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll = () => {
        const container = document.getElementById('chatContainer');
        if (container) {
            const distanceFromBottom = container.scrollHeight - container.scrollTop - container.clientHeight;

            setAtBottom(distanceFromBottom <= BOTTOM_THRESHOLD);

            setCanScroll(container.scrollHeight > container.clientHeight);
        }
    };

    useEffect(() => {
        const container = document.getElementById('chatContainer');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            setCanScroll(container.scrollHeight > container.clientHeight);
            scrollToBottom();

            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, []);


    useEffect(() => {
        if (isChatOpen) {
            scrollToBottom();
        }
    }, [isChatOpen, messages]);

    const handleSuggestionSwitch = () => {
        setShowSuggestions((prevValue: boolean) => {
            const newValue = !prevValue;
            localStorage.setItem('showSuggestions', JSON.stringify(newValue));
            setTimeout(() => {
                scrollToBottom();
            }, 150);
            return newValue;
        });
    };

    const handleButtonClick = () => {
        const updatedMessages = messages.map(msg => {
            const languageRegex = /\((\w{2})\)$/;
            const messageContent = msg.content.replace(languageRegex, '').trim();
            const previousLanguage = msg.content.match(languageRegex)?.[1];
            if (messageContent === "gotobuttons : true") {
                return { ...msg, content: "relocate : true(" + previousLanguage + ")" };
            }
            return msg;
        });

        setMessages(updatedMessages);

        setTimeout(async () => {
            const validatefileStatus = await validateTemplateFile()

            if (validatefileStatus) {
                navigate("/" + i18n.language + "/dashboard/cards/import/map#errors");
            } else {
                navigate("/" + i18n.language + "/dashboard/cards/import/map");
            }
        }, 5);
    };

    const validateTemplateFile = async () => {
        try {
            setIsAwaitingResponse(true)
            const data = await verifyTemplate(org_id).unwrap();
            if (data) {
                return data.response;
            } else {
                return false
            }
        } catch (err) {
            console.error(err)
        } finally {
            setIsAwaitingResponse(false);
        }
    }        

    return (
        <>
            <div className="fixed bottom-4 right-4 z-[100] pointer-events-none">

                <ChatBoxAnimations/>
                
                <div className={`pointer-events-auto flex flex-col ${!firstOpen && !isChatOpen ? 'translate-x-[500px]' : (!isChatOpen ? 'move-to-right translate-x-[500px]' : 'move-to-left')}`}>
                    <div className="relative w-[310px] md:w-[410px] h-[70vh] max-h-[600px] bg-white border border-[#D9E3F1] shadow-lg rounded-[31px] mb-2.5 flex flex-col">
                        <div className="rounded-t-[31px] bg-[#F5F8FB] border-b border-[#D9E3F1] h-[65px] flex flex-col md:flex-row justify-between p-4">
                            <div className="flex flex-row items-center">
                                <div className="ml-1 font-bold text-xl">{t("patrick:name")}</div>
                                <img src={wave} alt="Wave" className={`w-8 h-7 md:w-9 md:h-9 mr-2.5 ${!isWaveCooldown ? 'wave' : ''} `} />
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                {isOnline ?
                                    <>
                                        <img src={ellipse} alt="Status" className="w-2 h-2" />
                                        <div className="text-xs text-[#5D6C78]">{t("patrick:online")}</div>
                                    </>
                                    :
                                    <>
                                        <img src={ellipseRed} alt="Status" className="w-2 h-2" />
                                        <div className="text-xs text-[#5D6C78]">{t("patrick:offline")}</div>
                                    </>
                                }

                            </div>
                        </div>

                        <div id="chatContainer" className="flex flex-col flex-grow overflow-y-scroll p-2.5 z-10 no-scrollbar">
                            <ChatboxMessages messages={messages} handleButtonClick={handleButtonClick} isOnline={isOnline} first_name={first_name} last_name={last_name} isAwaitingResponse={isAwaitingResponse} isChatOpen={isChatOpen} />
                            <div ref={messagesEndRef} />

                        </div>

                        <div className='absolute bottom-[10px] left-[11px] h-[56.7px] rounded-l-full flex items-center overflow-hidden px-[14px] pointer-events-none'>
                            <FontAwesomeIcon
                                className={`p-1 bg-map-blue rounded-full cursor-pointer h-6 w-6 text-white z-10 pointer-events-auto ${canScroll ? (atBottom ? 'move-arrow-left' : 'move-arrow-right') : 'hidden'}`}
                                icon={faArrowDown}
                                onClick={scrollToBottom}
                            />
                        </div>

                        <div className={`border-t-[1px] border-[#D9E3F1] bg-[#FBFCFD] rounded-b-[31px] ${!isOnline && 'cursor-not-allowed'}`}>
                            <FontAwesomeIcon className={`z-20 absolute mt-[-11px] right-[5px] w-[10px] h-[10px] p-[5px] border-[1px] border-[#D9E3F1] bg-[#FBFCFD] cursor-pointer rounded-full transition-transform duration-200 ${showSuggestions ? '' : 'transform rotate-180'}`} icon={faChevronDown} onClick={handleSuggestionSwitch} />
                            <motion.div animate={(!showSuggestions) ? { height: '0rem' } : { height: 'auto' }} className={`overflow-hidden ${!isOnline && 'pointer-events-none'}`}>
                                <div className="mt-2 ml-2.5 mr-2.5">
                                    <ChatboxSuggestions isAwaitingResponse={isAwaitingResponse} onSuggestionClick={handleSuggestionClick} tabIndex={isChatOpen ? 0 : -1} />
                                </div>
                            </motion.div>

                            <div className={`bg-[#FFFFFF] rounded-full border border-[#D9E3F1] flex flex-row items-center p-1.5 m-2.5 max-h-[100px]`}>
                                <img src={chat} alt="Chat" className="m-3.5 md:flex hidden" />

                                <input tabIndex={isChatOpen ? 0 : -1} type="text" className="w-[65%] md:pl-0 pl-3 h-6 text-base border-none outline-none resize-none" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter" && !e.shiftKey && !isAwaitingResponse) { e.preventDefault(); handleSendMessage(); } }} placeholder={t("patrick:typeing") || ""} />

                                <input type="file" accept=".CSV, .XLS, .XLSX" className="hidden" id="csvFileInput" onChange={handleFileUpload} disabled={isAwaitingResponse} />
                                <label htmlFor="csvFileInput" className={`${!isOnline && 'pointer-events-none'} ${isAwaitingResponse ? 'cursor-default' : 'cursor-pointer'}`}>
                                    <div className={`${isAwaitingResponse ? '' : 'shake'} w-10 h-10 rounded-full flex items-center justify-center m-0.5 ${isAwaitingResponse ? 'bg-gray-400' : 'bg-[#F5F8FB]'}`}>
                                        <img src={paperclip} alt="File upload" className="shake-item w-2.5 h-4" />
                                    </div>
                                </label>

                                <button tabIndex={isChatOpen ? 0 : -1} onClick={() => handleSendMessage()} disabled={isAwaitingResponse} className={`${isAwaitingResponse ? '' : 'send-hover'} disabled:bg-[#5D6C78] disabled:cursor-default ${isInputNotEmpty ? 'bg-[#3273F6] cursor-pointer' : 'bg-[#285FCF] cursor-default'} transition-colors duration-300 w-10 h-10 rounded-full flex items-center justify-center m-0.5 overflow-hidden`} >
                                    <img src={send} alt="Send" className={`w-4 h-4.5 send-transformer ${isInputNotEmpty ? 'send-hover-item' : ''} ${isAwaitingResponse ? 'send' : ''}`} />
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flex flex-row">

                    {/* open introduction (text) */}
                    <div className='w-[270px] md:w-[370px] pb-5 -mb-5 overflow-hidden'>
                        <div className={`pointer-events-auto flex flex-row items-center relative justify-between w-[222px] md:w-[322px] h-[83px] bg-[#252525] rounded-[31px] mr-1.5 z-[1] transition-opacity duration-300 ${!firstOpenIntro ? (introVisible ? 'move-to-left-grow' : 'translate-x-[500px]') : (introVisible ? 'move-to-left-grow' : 'move-to-right-shrink translate-x-[500px]')}`}>
                            <div className="flex justify-center text-center flex-col flex-grow">
                                {getPathName(location.pathname) === "unknown" ? (
                                    <div className="text-[20px] md:text-lg text-white font-bold">{t("patrick:unknownLocationTitle")}</div> //if the popup says location unknown update the getPathName, if you're adding a new page mabye also add some suggestions :)
                                ) : (
                                    <>
                                        <div className="text-[20px] md:text-lg text-white font-bold">{t("patrick:greeting")}</div>
                                        <div className="text-[0px] md:text-[15px] text-white font-light">{t("patrick:help")}</div>
                                    </>
                                )}
                            </div>
                            <div tabIndex={introVisible ? 0 : -1} className={`absolute right-[-5px] bottom-[-5px] w-7 h-7 shadow-lg bg-[#252525] rounded-[40%] flex justify-center ${introVisible ? 'cursor-pointer' : 'cursor-default'} items-center`} onKeyDown={(e) => { if (e.key === "Enter") { introToggle(); } }} onClick={() => introToggle()} aria-label="Close introduction">
                                <img src={close} alt="Close introduction" className="w-3 h-3" />
                            </div>
                        </div>
                    </div>

                    {/* closed introduction (button) */}
                    <div className="flex flex-row items-center justify-between w-[0px] md:w-[0px] h-[83px] mr-1.5">
                        <div onKeyDown={(e) => { if (e.key === "Enter") { introToggle(); } }} tabIndex={introVisible ? -1 : 0} className={`pointer-events-auto absolute right-[90px] ${getPathName(location.pathname) === "unknown" ? 'border-dashed border-2 border-red-500' : ''} bg-[#F5F8FB] shadow-lg w-[40px] h-[40px] rounded-[50%] flex justify-center items-center ${!firstOpenIntro ? (!introVisible ? 'grow' : 'opacity-0 pointer-events-none') : (!introVisible ? 'grow cursor-pointer' : 'shrink cursor-default')}`} onClick={() => introToggle()} aria-label="Open introduction" >
                            <IconSVG className={`h-3 ${!firstOpenIntro ? (!introVisible ? 'rotate-full-in' : '') : (!introVisible ? 'rotate-full-in' : 'rotate-full-out')}`} />
                        </div>
                    </div>

                    {/* open chat */}
                    <div tabIndex={0} className={`pointer-events-auto w-[83px] h-[83px] bg-[#e3e2dc] fixed bottom-4 right-4 shadow-lg rounded-full flex justify-center items-center ${isCooldown ? '' : 'cursor-pointer'} overflow-hidden z-[2]`} onKeyDown={(e) => { if (e.key === "Enter") { isCooldown ? null : openChat(); } }} onClick={() => { isCooldown ? null : openChat(); }} >
                        <img src={bot} alt="Open Patrick assistance" className={`absolute top-[8px] ${isChatOpen ? 'move-down' : 'move-up'}`} />
                        {isWinter && (
                            <img src={santa} alt="Hat" className={`absolute w-10 -mt-[45px] -ml-[2px] rotate-[10deg] transition-transform duration-300 ${isChatOpen ? 'move-down-xmas' : 'move-up-xmas'}`} /> // this is where Patrick AI peaked
                        )}
                    </div>

                    {/* close chat */}
                    <div className={`pointer-events-auto w-[84px] h-[84px] bg-[#EA325C] fixed bottom-[15.5px] right-[15.5px] shadow-lg rounded-full flex justify-center items-center ${isCooldown ? '' : 'cursor-pointer'} overflow-hidden z-[2] transition-opacity duration-300 ${isChatOpen ? 'opacity-100' : 'opacity-0'}`} onClick={() => { isCooldown ? null : openChat(); }} >
                        <img src={close} alt="Close Patrick assistance" className={`w-5 h-5 ${!firstOpen ? '' : (isChatOpen ? 'rotate-90' : 'rotate-0')}`} />
                    </div>
                </div>

            </div>
            {getPathName(location.pathname) === "unknown" ? (
                <div className={`fixed bottom-[75px] right-[15px] w-[25px] h-[25px] bg-[#FF0000] rounded-full ${isCooldown ? '' : 'cursor-pointer'} z-[101] shadow-lg flex justify-center text-white animate-ping`} onClick={() => { isCooldown ? null : openChat(); }} >!</div> //update the getLanguage or getPathName read comments above for more info
            ) : null}
        </>
    );
};

export default ChatBox;
