import { AnimatePresence, motion } from 'framer-motion'
import { use } from 'i18next';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next";

export interface HourInputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label: string,
    sublabel?: string,
    id?: string,
    initial?: Date | string,
    required?: boolean,
    valueChange?: (value: Date) => void
    valuelabel?: string
    placeholder?: string
    submitted?: boolean
    isToday?: Date
}

const HourInput = (props: HourInputProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const { label, required, initial, valueChange, sublabel, id, valuelabel, className, placeholder, submitted, isToday, ...restProps } = props
    const { t, i18n } = useTranslation()

    const labelOrId = id || label
    const [value, setValue] = useState('')
    const [valueHour, setValueHour] = useState('')
    const [valueMin, setValueMin] = useState('')
    const [isOpenHour, setIsOpenHour] = useState(false)
    const [isOpenMin, setIsOpenMin] = useState(false)
    const [disableOldTime, setDisableOldTime] = useState(false)
    const [disableOldHours, setDisableOldHours] = useState(false)
    const [highlightedIndexHour, setHighlightedIndexHour] = useState<number | null>(null)
    const [highlightedIndexMin, setHighlightedIndexMin] = useState<number | null>(null)
    const refHour = useRef<HTMLDivElement>(null)
    const refMin = useRef<HTMLDivElement>(null)
    const dropdownRefHour = useRef<HTMLDivElement>(null)
    const dropdownRefMin = useRef<HTMLDivElement>(null)
    const KeyArray = ['Escape', 'Enter', 'ArrowDown', 'ArrowUp']
    const NumberArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    useEffect(() => {
        if (isToday?.toDateString() === (new Date()).toDateString()) {
            setDisableOldHours(true);
            setValueHour('');
            setValueMin('');
        } else {
            setDisableOldHours(false);
        }
    }, [isToday])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (refHour.current && !refHour.current.contains(event.target as Node)) {
                setIsOpenHour(false);
            }
            if (refMin.current && !refMin.current.contains(event.target as Node)) {
                setIsOpenMin(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setValue(`${valueHour}:${valueMin}`)
        if (valueChange) {
            valueChange(new Date(`${valueHour}:${valueMin}`))
        }
    }, [valueHour, valueMin])

    useEffect(() => {
        if (highlightedIndexHour !== null && dropdownRefHour.current) {
            const element = dropdownRefHour.current.children[1].children[highlightedIndexHour] as HTMLElement;
            element?.scrollIntoView({ block: 'nearest' });
        }
    }, [highlightedIndexHour])

    useEffect(() => {
        if (disableOldHours && (valueHour === currentHours.toString().padStart(2, '0'))) {
            setDisableOldTime(true);
            setValueMin('');
        } else {
            setDisableOldTime(false);
        }
    }, [valueHour])

    const handleKeyDownHour = (e: React.KeyboardEvent) => {
        if (KeyArray.includes(e.key)) {
            e.preventDefault()
        }
        if (NumberArray.includes(e.key) && isOpenHour) {
            if (highlightedIndexHour !== null && highlightedIndexHour.toString().length < 2 && (highlightedIndexHour + '' + e.key < '24')) {
                setHighlightedIndexHour(parseInt(highlightedIndexHour + '' + parseInt(e.key)))
            } else {
                setHighlightedIndexHour(parseInt(e.key))
            }
        }
        if (e.key === 'Escape') {
            setIsOpenHour(false)
        }
        if (e.key === 'Enter') {
            if (!isOpenHour) setIsOpenHour(true)
            else {
                if (disableOldHours && highlightedIndexHour !== null && (currentHours > highlightedIndexHour || (currentHours === highlightedIndexHour && currentMinutes >= 45))) {
                    return null;
                } else {
                    setValueHour((highlightedIndexHour || 0).toString().padStart(2, '0')); setIsOpenHour(false); setHighlightedIndexHour(null);
                }
            }
        }
        if (e.key === 'ArrowDown') {
            if (highlightedIndexHour === null) {
                setHighlightedIndexHour(0)
            } else {
                setHighlightedIndexHour((highlightedIndexHour + 1) % 24)
            }
        }
        if (e.key === 'ArrowUp') {
            if (highlightedIndexHour === null) {
                setHighlightedIndexHour(23)
            } else {
                setHighlightedIndexHour((highlightedIndexHour + 23) % 24)
            }
        }
        if (e.key === 'Tab') {
            setIsOpenHour(false)
        }
    }

    const handleKeyDownMin = (e: React.KeyboardEvent) => {
        if (KeyArray.includes(e.key)) {
            e.preventDefault()
        }
        if (['0', '1'].includes(e.key) && isOpenMin) {
            setHighlightedIndexMin(parseInt(e.key))
        }
        if ('3' === e.key && isOpenMin) {
            setHighlightedIndexMin(2)
        }
        if ('4' === e.key && isOpenMin) {
            setHighlightedIndexMin(3)
        }
        if (e.key === 'Escape') {
            setIsOpenMin(false)
        }
        if (e.key === 'Enter') {
            if (!isOpenMin) setIsOpenMin(true)
            else {
                if (disableOldTime && (currentHours > parseInt(valueHour) || (currentHours === parseInt(valueHour) && currentMinutes > (15 * (highlightedIndexMin || 0))))) {
                    return null;
                } else {
                    setValueMin(((highlightedIndexMin || 0) * 15).toString().padStart(2, '0')); setIsOpenMin(false); setHighlightedIndexMin(null);
                }
            }
        }
        if (e.key === 'ArrowDown') {
            if (highlightedIndexMin === null) {
                setHighlightedIndexMin(0)
            } else {
                setHighlightedIndexMin((highlightedIndexMin + 1) % 4)
            }
        }
        if (e.key === 'ArrowUp') {
            if (highlightedIndexMin === null) {
                setHighlightedIndexMin(3)
            } else {
                setHighlightedIndexMin((highlightedIndexMin + 3) % 4)
            }
        }
        if (e.key === 'Tab') {
            setIsOpenMin(false)
        }
    }

    return (
        <div {...restProps} ref={ref} className={`flex flex-col ${className}`}>
            <label htmlFor={labelOrId}>
                {label}{required && <span className='text-red-400'>*</span>} {sublabel && <span className='opacity-70 text-sm'>({sublabel})</span>}
            </label>
            <div className='flex gap-2'>
                <div ref={refHour} className='flex w-full relative'>
                    <div
                        id='hour'
                        tabIndex={0}
                        onKeyDown={handleKeyDownHour}
                        onClick={() => { setIsOpenHour(!isOpenHour); setHighlightedIndexHour(null) }}
                        className={`flex items-center cursor-pointer rounded-[0.4rem] flex-grow p-2 px-3 ${submitted && !valueHour ? 'border-red-500 border' : 'border-[1px] border-black border-opacity-15'} ${valueHour ? '' : 'text-gray-300'}`}>
                        <div className='flex items-center'>
                            {valueHour ? (
                                <div className='flex items-center text-black'>
                                    {valueHour}
                                </div>
                            ) : (
                                <div>{`\u00A0`}</div>
                            )}
                        </div>
                    </div>
                    <AnimatePresence>
                        {isOpenHour && (
                            <motion.div
                                layout
                                initial={{ height: 0 }}
                                animate={{ height: 'auto' }}
                                exit={{ height: 0 }}
                                transition={{ type: "smooth", duration: 0.2 }}
                                tabIndex={-1}
                                className={`absolute left-0 right-0 mb-12 bottom-0 overflow-hidden border-2 flex bg-light-200 rounded-md z-40 flex-col max-h-[300px]`}
                                ref={dropdownRefHour}
                            >
                                <h1 className='p-2 border-b bg-gray-100 text-center font-bold'>{t('hour')}</h1>
                                
                                <div tabIndex={-1} className='overflow-y-auto'>
                                    {Array.from({ length: 24 }, (_, i) => (
                                        <div
                                            key={i}
                                            onClick={() => {
                                                if (disableOldHours && (currentHours > i || (currentHours === i && currentMinutes >= 45))) {
                                                    return null;
                                                }
                                                setValueHour(i.toString().padStart(2, '0'));
                                                setIsOpenHour(false);
                                                if (!valueMin) setIsOpenMin(true);
                                            }}
                                            className={`
                                                border-b text-center 
                                                ${highlightedIndexHour === i ? ' bg-blue-200' : ''}
                                                ${valueHour === i.toString().padStart(2, '0') ? 'bg-blue-300' : ''}
                                                ${disableOldHours && (currentHours > i || (currentHours === i && currentMinutes >= 45)) ? 'opacity-50 cursor-not-allowed py-1' : 'cursor-pointer hover:bg-light-300 py-2'}
                                                `}
                                        >
                                            {i.toString().padStart(2, '0')}
                                        </div>
                                    ))}
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                <div className='text-[1.5rem]'>:</div>

                <div ref={refMin} className='flex w-full relative'>
                    <div
                        id='min'
                        tabIndex={0}
                        onKeyDown={handleKeyDownMin}
                        onClick={() => { setIsOpenMin(!isOpenMin); setHighlightedIndexMin(null); }}
                        className={`flex items-center cursor-pointer rounded-[0.4rem] flex-grow p-2 px-3 ${submitted && !valueMin ? 'border-red-500 border' : 'border-[1px] border-black border-opacity-15'} ${valueMin ? '' : 'text-gray-300'}`}>
                        <div className='flex items-center'>
                            {valueMin ? (
                                <div className='flex items-center text-black'>
                                    {valueMin}
                                </div>
                            ) : (
                                <div>{`\u00A0`}</div>
                            )}
                        </div>
                    </div>
                    <AnimatePresence>
                        {isOpenMin && (
                            <motion.div
                                layout
                                initial={{ height: 0 }}
                                animate={{ height: 'auto' }}
                                exit={{ height: 0 }}
                                transition={{ type: "smooth", duration: 0.2 }}
                                tabIndex={-1}
                                className={`absolute left-0 right-0 mb-12 bottom-0 overflow-hidden border-2 flex bg-light-200 rounded-md z-40 flex-col max-h-[300px] overflow-y-auto`}
                                ref={dropdownRefMin}
                            >
                                <h1 className='p-2 border-b bg-gray-100 text-center font-bold'>{t('minute')}</h1>
                                <div tabIndex={-1} className='overflow-y-auto'>
                                    {Array.from({ length: 4 }, (_, i) => (
                                        <div
                                            key={i}
                                            onClick={() => {
                                                if (disableOldTime && (currentHours > parseInt(valueHour) || (currentHours === parseInt(valueHour) && currentMinutes > (15 * i)))) {
                                                    return null;
                                                }
                                                setValueMin((15 * i).toString().padStart(2, '0'));
                                                setIsOpenMin(false);
                                            }}
                                            className={`
                                            border-b text-center
                                            ${highlightedIndexMin === i ? ' bg-blue-200' : ''}
                                            ${valueMin === (15 * i).toString().padStart(2, '0') ? 'bg-blue-300' : ''}
                                            ${disableOldTime && (currentHours > parseInt(valueHour) || (currentHours === parseInt(valueHour) && currentMinutes > (15 * i))) ? 'opacity-50 cursor-not-allowed py-1' : 'cursor-pointer hover:bg-light-300 py-2'}
                                            `}
                                        >
                                            {(15 * i).toString().padStart(2, '0')}
                                        </div>
                                    ))}
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
            <input value={value?.toString()} type='hidden' name={labelOrId} id={labelOrId} />
            <input name={'@VALID@' + labelOrId} type="hidden" value={(!required || value.length > 4) + ''} />
            <AnimatePresence>
                {(value.length !== 5 && submitted) && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: '1.2rem' }}
                        exit={{ height: 0 }}
                        className="text-sm overflow-hidden text-red-400 mb-2"
                    >
                        {(value.length !== 5 && required && t('required')) || '\u00a0'}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default HourInput;
