import { useTranslation } from "react-i18next";

const ChatBoxAnimations: React.FC = () => {
    const { t } = useTranslation();

    return (
        <style>
            {`
                .large-code {
                    background-color: #F5F8FB;
                    padding: 2px 4px;
                    border-radius: 4px;
                    display: block;
                }

                .normal-code {
                    background-color: #F5F8FB;
                    padding: 2px 4px;
                    border-radius: 4px;
                }

                .loader {
                    position: relative;
                    display: inline-block;
                    overflow: hidden;
                    background-color: #E7E7E7;
                }

                .loader::before {
                    content: '${t(' patrick: typingLoader')}';
                    position: absolute;
                    transform: translateY(-10px);
                    animation: typing 3s infinite;
                    z-index: 1;
                }

                @keyframes typing {
                    25% {
                        content: '${t(' patrick: typingLoader')}';
                    }

                    50% {
                        content: '${t(' patrick: typingLoader')}.';
                    }

                    75% {
                        content: '${t(' patrick: typingLoader')}..';
                    }

                    100% {
                        content: '${t(' patrick: typingLoader')}...';
                    }
                }

                .loader::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.05);
                    animation: loading 3s infinite;
                    z-index: 0;
                }

                .busy {
                    position: relative;
                    display: inline-block;
                    overflow: hidden;
                    background-color: #E7E7E7;
                }

                .busy::before {
                    content: '${t(' patrick: busy')}';
                    position: absolute;
                    transform: translateY(-10px);
                    animation: busy 3s infinite;
                    z-index: 1;
                }

                @keyframes busy {
                    25% {
                        content: '${t(' patrick: busy')}';
                    }

                    50% {
                        content: '${t(' patrick: busy')}.';
                    }

                    75% {
                        content: '${t(' patrick: busy')}..';
                    }

                    100% {
                        content: '${t(' patrick: busy')}...';
                    }
                }

                .busy::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.05);
                    animation: loading 3s infinite;
                    z-index: 0;
                }

                @keyframes loading {
                    0% {
                        left: -100%;
                        width: 100%;
                    }

                    50% {
                        left: 0;
                        width: 100%;
                    }

                    100% {
                        left: 100%;
                        width: 0;
                    }
                }

                .rotate-90 {
                    animation: rotate-90 0.5s ease-in-out;
                }

                @keyframes rotate-90 {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(90deg);
                    }
                }

                .rotate-0 {
                    animation: rotate-0 0.5s ease-in-out;
                }

                @keyframes rotate-0 {
                    0% {
                        transform: rotate(90deg);
                    }

                    100% {
                        transform: rotate(0deg);
                    }
                }

                .move-up {
                    animation: move-up 0.5s ease-in-out;
                }

                @keyframes move-up {
                    0% {
                        transform: translateY(20px);
                    }

                    100% {
                        transform: translateY(0);
                    }
                }

                .move-down {
                    transform: translateY(20px);
                    animation: move-down 0.5s ease-in-out;
                }

                @keyframes move-down {
                    0% {
                        transform: translateY(0);
                    }

                    100% {
                        transform: translateY(20px);
                    }
                }

                .move-up-xmas {
                    animation: move-up-xmas 0.5s ease-in-out;
                }

                @keyframes move-up-xmas {
                    0% {
                        transform: translateY(20px);
                        rotate: 0deg;
                    }

                    100% {
                        transform: translateY(0);
                        rotate: 10deg;
                    }
                }

                .move-down-xmas {
                    transform: rotate(0deg);
                    transform: translateY(20px);
                    animation: move-down-xmas 0.5s ease-in-out;
                }

                @keyframes move-down-xmas {
                    0% {
                        transform: translateY(0);
                        rotate: 10deg;
                    }

                    100% {
                        transform: translateY(20px);
                        rotate: 0deg;
                    }
                }

                .move-to-left {
                    animation: move-to-left 0.5s ease-out;
                }

                @keyframes move-to-left {
                    0% {
                        transform: translateX(500px) scale(0.75);
                    }

                    100% {
                        transform: translateX(0)scale(1);
                    }
                }

                .move-to-right {
                    animation: move-to-right 0.5s ease-in;
                }

                @keyframes move-to-right {
                    0% {
                        transform: translateX(0) scale(1);
                    }

                    100% {
                        transform: translateX(500px) scale(0.75);
                    }
                }

                .move-to-left-grow {
                    animation: move-to-left-grow 1s ease-in-out;
                }

                @keyframes move-to-left-grow {
                    0% {
                        transform: translateX(500px) scale(0);
                    }

                    100% {
                        transform: translateX(0) scale(1);
                    }
                }

                .move-to-right-shrink {
                    animation: move-to-right-shrink 1s ease-in-out;
                }

                @keyframes move-to-right-shrink {
                    0% {
                        transform: translateX(0) scale(1);
                    }

                    100% {
                        transform: translateX(500px) scale(0);
                    }
                }

                .grow {
                    animation: grow 1s ease-in-out;
                }

                @keyframes grow {
                    0% {
                        transform: scale(0);
                    }

                    50% {
                        transform: scale(0);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                .shrink {
                    animation: shrink 1s ease-in-out;
                    transform: scale(0);
                }

                @keyframes shrink {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(0);
                    }

                    100% {
                        transform: scale(0);
                    }
                }

                .wave {
                    animation: wave 1.5s ease-in-out;
                    transform-origin: bottom right;
                }

                @keyframes wave {
                    0% {
                        transform: rotate(0deg);
                    }

                    25% {
                        transform: rotate(10deg);
                    }

                    50% {
                        transform: rotate(-10deg);
                    }

                    75% {
                        transform: rotate(10deg);
                    }

                    100% {
                        transform: rotate(0deg);
                    }
                }

                .rotate-full-in {
                    animation: rotate-full-in 1s ease-in-out;
                }

                @keyframes rotate-full-in {
                    0% {
                        transform: rotate(0deg);
                    }

                    50% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }

                .rotate-full-out {
                    animation: rotate-full-out 1s ease-in-out;
                }

                @keyframes rotate-full-out {
                    0% {
                        transform: rotate(360deg);
                    }

                    50% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(0deg);
                    }
                }

                .send-transformer {
                    transition: transform 0.8s ease-in-out;
                }

                .send-hover:hover .send-hover-item {
                    animation: send-hover 1.5s ease-in-out infinite;
                }

                @keyframes send-hover {
                    0% {
                        transform: translate(-0px, 0px);
                    }

                    60% {
                        transform: translate(-3px, 3px);
                    }

                    75% {
                        transform: translate(1px, -1px);
                    }

                    85% {
                        transform: translate(-0px, 0px);
                    }

                    100% {
                        transform: translate(-0px, 0px);
                    }
                }

                .send {
                    animation: send 1s ease-in-out;
                    transform: translate(-30px, 30px);
                }

                @keyframes send {
                    0% {
                        transform: translate(2px, -2px);
                    }

                    35% {
                        transform: translate(-10px, 10px);
                    }

                    100% {
                        transform: translate(20px, -20px);
                    }
                }

                .shake:hover .shake-item {
                    animation: shake 0.5s ease-in-out;
                }

                @keyframes shake {
                    0% {
                        transform: rotate(0deg);
                    }

                    25% {
                        transform: rotate(15deg);
                    }

                    50% {
                        transform: rotate(-10deg);
                    }

                    75% {
                        transform: rotate(10deg);
                    }

                    100% {
                        transform: rotate(0deg);
                    }
                }

                .move-arrow-right {
                    animation: move-arrow-right 0.5s linear;
                }

                @keyframes move-arrow-right {
                    0% {
                        transform: translateX(-50px) rotate(-90deg);
                    }

                    75% {
                        transform: translateX(0) rotate(-90deg);
                    }

                    100% {
                        transform: translateX(0) rotate(0deg);
                    }
                }

                .move-arrow-left {
                    animation: move-arrow-left 0.5s linear;
                    transform: translateX(-50px) rotate(90deg);
                }

                @keyframes move-arrow-left {
                    0% {
                        transform: translateX(0) rotate(0deg);
                    }

                    25% {
                        transform: translateX(0) rotate(90deg);
                    }

                    100% {
                        transform: translateX(-50px) rotate(90deg);
                    }
                }
            `}
        </style>
    );
};

export default ChatBoxAnimations;
