import Api from "./Api";
import {BlockRequest, BlockResponse} from './Request/Block';
import {CardRequest, CardResponseItem, PutCardResponseItem} from './Request/Cards';
import {MassActionRequest, MassActionResponse} from './Request/MassAction';
import {AddCardPhotoRequest, AddPhotoResponse, PhotoNumber} from "./Request/AddPhoto";
import {AddMultiplePhotoRequest} from "./Request/AddMultiplePhoto";

const cardApi = Api.injectEndpoints({
    endpoints: build => ({
        putCard: build.mutation<void, Partial<PutCardResponseItem>>({
            query: (card) => ({
                url:`/api/cards/${ card.id }`,
                method: 'PUT',
                body: JSON.stringify(card)
            })
        }),
        deleteCard: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/cards/${ id }`,
                method: 'DELETE'
            })
        }),
        postCard: build.mutation<CardResponseItem, [string, Partial<CardResponseItem>]>({
            query: ([id, card]) => ({
                url:`/api/cards`,
                method: 'POST',
                body: JSON.stringify({...card, organisation_id: id})
            })
        }),

        postBlock: build.mutation<BlockResponse, [string, BlockRequest]>({
            query: ([id, block]) => ({
                url:`/api/cards/${ id }/block`,
                method: 'POST',
                body: JSON.stringify(block)
            })
        }),
        postUnblock: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/cards/${ id }/unblock`,
                method: 'DELETE'
            })
        }),

        postCardsMassAction: build.mutation<MassActionResponse, MassActionRequest>({
            query: (request) => ({
                url: `/api/cards/mass-action`,
                method: 'POST',
                body: JSON.stringify(request)
            })
        }),
        postActivationLinkEmail: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/cards/${ id }/send-activation`,
                method: 'POST'
            })
        }),

        getBlocks: build.query<Array<CardResponseItem['block']>, string>({
            query: (id) => (`/api/cards/${ id }/blocks`)
        }),

        getCard: build.query<CardResponseItem, CardRequest>({
            query: ({ id }) => (`/api/cards/${ id }`),
        }),

        addCardPhoto: build.mutation<AddPhotoResponse, AddCardPhotoRequest>({
            query: ({card_id, upload_id}) => ({
                url: `/api/cards/${ card_id }/add-photo`,
                method: 'POST',
                body: JSON.stringify({file: upload_id})
            })
        }),

        addMultiplePhoto: build.mutation<void, AddMultiplePhotoRequest>({
            query: ({card_ids, upload_ids}) => ({
                url: `/api/cards/add-multiple-photo`,
                method: 'POST',
                body: JSON.stringify({files: upload_ids, cardIds: card_ids})
            })
        }),

        removeCardPhoto: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/cards/${ id }/remove-photo`,
                method: 'POST'
            })
        }),
        getAllMembershipIds: build.mutation<void, string>({
            query: () => ({
                url: '/api/cards',
                method: 'GET'
            })
            
        }),

        activateCard: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/cards/${ id }/activate`,
                method: 'POST'
            })
        })
    }),
});

export const {
    useGetCardQuery,
    useLazyGetCardQuery,
    usePutCardMutation,
    usePostBlockMutation,
    useLazyGetBlocksQuery,
    useGetBlocksQuery,
    usePostUnblockMutation,
    usePostActivationLinkEmailMutation,
    usePostCardMutation,
    useDeleteCardMutation,
    usePostCardsMassActionMutation,
    useAddCardPhotoMutation,
    useAddMultiplePhotoMutation,
    useRemoveCardPhotoMutation,
    useGetAllMembershipIdsMutation,
    useActivateCardMutation
} = cardApi;
export default cardApi;