import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CopyItemProps {
    name: string;
    copyItem: string;
}

const CopyItem: React.FC<CopyItemProps> = ({ name, copyItem }) => {
    const [copiedMessages, setCopiedMessages] = useState<Array<{ x: number; y: number }>>([]);
    const { t } = useTranslation();

    const handleMouseClick = (e: React.MouseEvent<HTMLSpanElement>, text: string) => {
        copyLink(text, e.clientX, e.clientY);
    };

    const copyLink = async (text: string, x: number, y: number) => {
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        } else {
            document.execCommand('copy', true, text);
        }
        setCopiedMessages((prev) => [...prev, { x, y }]);
    };

    return (
        <>
            <div className='flex flex-wrap gap-x-1'>
                <span>{name}</span>
                <span className='text-accent transition-colors cursor-pointer break-all' onClick={(e) => handleMouseClick(e, copyItem)}>{copyItem}</span>
                <span className='opacity-40 cursor-pointer' tabIndex={0} onKeyDown={(e) => copyLink(copyItem, 0, 0)} onClick={(e) => handleMouseClick(e, copyItem)}>{t('cards:edit:clickToCopy')}</span>
            </div>
            {copiedMessages.map((pos, index) => (
                <div key={index} className='fixed top-0 left-0 w-full h-full z-50 pointer-events-none'>
                    <div className='ml-[320px] lg:ml-0' style={{ marginLeft: pos.x, marginTop: pos.y, color: 'blue', padding: '5px 10px', borderRadius: '5px', whiteSpace: 'nowrap', pointerEvents: 'none', animation: 'fadeMoveUp 1.5s forwards', fontWeight: 'bold', zIndex: '110' }} >
                        {t('cards:edit:copied') as string}
                    </div>
                </div>
            ))}
        </>
    );
};

export default CopyItem;
