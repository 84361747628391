import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetOrganisationsQuery, useLazyGetOrganisationSettingsQuery } from '../../../../Redux/Api/Organisations';
import { useGetUserQuery, usePutUserMutation } from '../../../../Redux/Api/Users';
import { useAppSelector, useAppDispatch } from '../../../../Redux/store'
import { Strings } from '../../../../Strings/nl';
import { Hydra } from '../../../../Utils/Hydra'
import { JWT } from '../../../../Utils/JWT'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Page404 from '../../Page404'
import { useTranslation } from "react-i18next";
import { faFloppyDisk, faLock, faLockOpen, faEnvelope, faArrowLeft, faQrcode, faUsers, faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { useForgotPasswordMutation } from '../../../../Redux/Api/Auth';
import { useNavigate } from 'react-router-dom';
import CardSelect from '../../../Shared/Card/CardSelect';
import CardGrid from '../../../Shared/Card/CardGrid';
import { logout } from '../../../../Redux/State/Auth';

const UsersEditPage = () => {

    const modalContext = useContext(ModalContext)
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { id: userId } = useParams()

    if (!userId) return <Page404 />

    const org_id = useAppSelector((s) => s.organisation.id)!
    const org_id_ifadmin = localStorage.getItem('active_organisation')!
    const roles = JWT.read<Array<string>>('roles') || []
    const hidden = { hidden: 'false' };

    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery();

    const { data: orgs } = useGetOrganisationsQuery(hidden, {
        refetchOnMountOrArgChange: true
    });

    const { data, refetch, isError } = useGetUserQuery(userId!)
    const [putUser] = usePutUserMutation()

    const [isActive, setIsActive] = useState(true)
    const [error, setError] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [busy, setBusy] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [forgotPassword, setForgotPassword] = useForgotPasswordMutation();
    const [role, setRole] = useState('');
    const [notificationAccess, setNotificationAccess] = useState(false);
    const [sponsorAccess, setSponsorAccess] = useState(false);
    const [patrickAssistantAccess, setPatrickAssistantAccess] = useState(false);
    const dispatch = useAppDispatch();

    let userEmail = '';

    useEffect(() => {
        if (org_id) getOrganisationsSettings(org_id);
    }, [org_id, getOrganisationsSettings]);

    useEffect(() => {
        if (!data) return

        setIsActive(data.active)
        setRole(data.role || 'ROLE_USER')
    }, [data])

    useEffect(() => {
        if (settings) {
            setNotificationAccess(!!settings.notification)
            setSponsorAccess(!!settings.sponsors)
            setPatrickAssistantAccess(!!settings.patrickAI)
        }
    }, [settings]);

    const submit = async (data: { email: string, firstname: string, lastname: string, organisationId: string, role: string }) => {
        setSubmitted(true)
        if (FailedRequirements(data, 'firstname', 'lastname', 'role')) return setError(t('modal:missing') as string)
        if (busy) return

        if (['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SOCIAL'].includes(role)) {
            data.role = role;
        } else {
            setError(t('user:list:invalidRole') as string);
            setBusy(false);
            return;
        }


        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await putUser([userId, data]).unwrap()

            refetch()
            setSuccessMessage(t('user:list:success') as string)
            if (userId === JWT.read('sub')) {
                dispatch(logout());
                window.location.hash = '#re-login';
            }

        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const changeActivation = async (new_state: boolean) => {
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await putUser([userId, {
                active: new_state
            }]).unwrap()

            refetch()
            setSuccessMessage(t('user:list:user') as string)
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const activate = () => changeActivation(true)
    const deactivate = () => changeActivation(false)

    const submitReset = async (data: { email: string; language: string }) => {
        if (busy) return;

        setBusy(true);
        setSuccessMessage('');
        setError('');

        const requestData = {
            email: userEmail,
            language: i18n.language
        };

        try {
            await forgotPassword(requestData).unwrap();
            setSuccessMessage(t("forgotPassword:mailSend") + userEmail);
        } catch (e) {
            setError(t("forgotPassword:error") || "");
        }
        setBusy(false);
    };
    if (isError) return <Page404 />
    if (!data || !orgs || !settings) return <LoadingPage />

    if (data) { userEmail = data.email; }

    const buildExtraFeatures = () => {
        let extraFeatures = '';
        if (notificationAccess) extraFeatures += t('user:list:extraFeaturesNotification') + ', ';
        if (sponsorAccess) extraFeatures += t('user:list:extraFeaturesSponsor') + ', ';
        if (patrickAssistantAccess) extraFeatures += t('user:list:extraFeaturesPatrick') + ', ';
        return extraFeatures
    }

    return (
        <>
            <Title text={t('user:list:edit')} textNotBold />
            <StatusPopups setText={setError} type='error' text={error} />
            <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />
            <BusyArea busy={busy}>
                <Form className='mb-4' submit={submit}>
                    <Input
                        submitted={submitted ? true : false}
                        required
                        initial={data.email}
                        label={t('user:list:email')}
                        type='email'
                        id='email' />
                    <Input
                        submitted={submitted ? true : false}
                        required
                        initial={data.firstname}
                        label={t('user:list:firstname')}
                        id='firstname' />
                    <Input
                        submitted={submitted ? true : false}
                        required
                        initial={data.lastname}
                        label={t('user:list:lastname')}
                        id='lastname' />

                    <input type='hidden' value={(org_id && !roles.includes('ROLE_SUPER_ADMIN')) ? (org_id) : (org_id_ifadmin)} name='organisationId' />

                    {roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_ADMIN') ? (
                        <>
                            <h1 className='-mb-4'>{t('user:list:role')}<span className='text-red-400'>*</span></h1>
                            <CardGrid cols={3}>
                                <CardSelect
                                    title={t('user:list:adminRole')}
                                    subText={t('user:list:adminRoleDescription')}
                                    onChange={() => setRole('ROLE_ADMIN')}
                                    isActive={role === 'ROLE_ADMIN'}
                                    icon={faUserTie}
                                    rounded
                                />
                                <CardSelect
                                    title={t('user:list:userRole')}
                                    subText={t('user:list:userRoleDescription')}
                                    onChange={() => setRole('ROLE_USER')}
                                    isActive={role === 'ROLE_USER'}
                                    icon={faQrcode}
                                    rounded
                                />
                                <CardSelect
                                    title={t('user:list:socialUserRole')}
                                    subText={t('user:list:socialUserRoleDescription', { extraFeatures: buildExtraFeatures() })}
                                    onChange={() => setRole('ROLE_SOCIAL')}
                                    isActive={role === 'ROLE_SOCIAL'}
                                    icon={faUsers}
                                    rounded
                                />
                            </CardGrid>
                        </>
                    ) : (
                        <input type='hidden' value={data.role} name='role' />
                    )}
                    <div className='flex flex-wrap justify-between mt-4 gap-4'>
                        <div className='flex flex-wrap gap-6'>
                            <Button icon={faFloppyDisk} iconright title={t('user:list:save')} />
                            <Button icon={faArrowLeft} title={t('back')} action={() => navigate(`/` + i18n.language + `/dashboard/users/list`)} />
                        </div>

                        <div className='flex flex-wrap gap-4'>
                            <Button
                                icon={isActive ? faLock : faLockOpen} iconright
                                nosubmit title={(isActive ? t('user:list:deactivate') : t('user:list:activate'))}
                                action={modalContext.withModal({ title: t('modal:attention'), body: t('modal:changes') }, isActive ? deactivate : activate)} />
                            <Button
                                icon={faEnvelope} iconright
                                nosubmit title={t('forgotPassword:reset')} action={submitReset} />
                        </div>
                    </div>
                </Form>
            </BusyArea>
        </>
    )
}

export default UsersEditPage
