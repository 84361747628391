import { AnimatePresence, motion } from 'framer-motion';
import { createContext, ReactNode, useState, useCallback, useEffect } from 'react';
import Button from '../Form/Button';
import Title from '../Title';
import { useTranslation } from "react-i18next";
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

export const ModalContext = createContext<{
    show: (title: string, body: string) => Promise<boolean>,
    hide: () => void,
    withModal: (display: { title: string, body: string }, onYes: Function, onNo?: Function) => () => Promise<void>
}>({
    show: async () => false,
    hide: () => void 0,
    withModal: () => async () => void 0
});

const Modal = ({ children }: { children: ReactNode }) => {

    const [state, setState] = useState({
        title: '',
        body: ''
    });

    const [isVisible, setIsVisible] = useState(false);
    const [closeAction, setCloseAction] = useState<(value: boolean) => void>(() => void 0);
    const { t } = useTranslation();

    const [activeButton, setActiveButton] = useState<'yes' | 'no' | ''>('');

    const show = (title: string, body: string) => {
        return new Promise<boolean>((res) => {
            setIsVisible(true);
            setState({ title, body });
            setCloseAction(() => (value: boolean) => res(value));
        });
    };

    const hide = () => {
        setIsVisible(false);
    };

    const withModal = (display: { title: string, body: string }, onYes: Function, onNo?: Function) => {
        return async () => {
            const act = await show(display.title, display.body);
            if (act) {
                onYes();
            } else if (onNo) {
                onNo();
            }
            hide();
        };
    };

    const toggleActiveButton = (direction: 'left' | 'right') => {;
        setActiveButton((prev) => (direction === 'left' ? (prev === '' ? 'no' : (prev === 'yes' ? 'no' : 'yes')) : (prev === '' ? 'yes' : (prev === 'no' ? 'yes' : 'no'))));
    };

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeAction(false);
            setActiveButton('');
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
            toggleActiveButton(e.key === 'ArrowRight' ? 'right' : 'left');
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (activeButton === 'yes' || activeButton === 'no') {
                const shouldClose = activeButton === 'yes';
                closeAction(shouldClose);
                setActiveButton('');
            }
        } else if (e.key === 'Tab') {
            e.preventDefault();
            toggleActiveButton('right');
        }
    }, [closeAction, activeButton]);

    useEffect(() => {
        if (isVisible) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isVisible, handleKeyDown]);

    return (
        <ModalContext.Provider value={{ show, hide, withModal }}>
            <AnimatePresence>
                {isVisible && (
                    <div className="fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-[111]">
                        <motion.div
                            initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                            exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            className="flex flex-col z-[110] rounded-[0.4rem] min-w-[33vw] max-w-[90vw] bg-light-200"
                        >
                            <div className="flex flex-col m-20">
                                <Title text={state.title} />
                                <div className="mt-4">{state.body}</div>
                                <div className="flex flex-wrap gap-4 mt-8">
                                    <Button
                                        icon={faCheck}
                                        active={activeButton === 'yes'}
                                        nosubmit
                                        title={t('cards:list:yes')}
                                        action={() => {closeAction(true); setActiveButton('');}}
                                    />
                                    <Button
                                        icon={faXmark}
                                        active={activeButton === 'no'}
                                        secondary
                                        nosubmit
                                        title={t('cards:list:no')}
                                        action={() => {closeAction(false); setActiveButton('');}}
                                    />
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            key="background-div"
                            onClick={() => {hide(); setActiveButton('');}}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.3 }}
                            exit={{ opacity: 0 }}
                            className="fixed left-0 top-0 w-screen h-screen bg-black"
                        />
                    </div>
                )}
            </AnimatePresence>
            {children}
        </ModalContext.Provider>
    );
};

export default Modal;