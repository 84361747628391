import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { TableContext } from './Table'

const TablePagination = () => {

    const ctx = useContext(TableContext)

    const [ totalPages, setTotalPages ] = useState(0)

    useEffect(() => {
        setTotalPages(Math.ceil(ctx.total / ctx.itemsPerPage))
    }, [ ctx.total, ctx.itemsPerPage ])

    const prev = () => {
        if(ctx.page > 0) {
            ctx.setPage(ctx.page - 1)
        }
    }

    const next = () => {
        if(ctx.page < totalPages - 1) {
            ctx.setPage(ctx.page + 1)
        }
    }

    const goToFirst = () => {
        if(ctx.page > 0) {
            ctx.setPage(0)
        }
    }

    const goToLast = () => {
        if(ctx.page < totalPages - 1) {
            ctx.setPage(totalPages - 1)
        }
    }

    return (
        <div id='skipTable' className='flex items-center justify-center my-5'>
            <div className='flex gap-2 justify-center items-center'>
                <div tabIndex={ctx.page > 0 ? 0 : -1} onKeyDown={ (e) => e.key === 'Enter' && goToFirst() } onClick={ goToFirst } className={`${ ctx.page > 0 ? 'cursor-pointer' : 'opacity-25 pointer-events-none' } select-none flex items-center justify-center w-10 h-10`}>
                    <FontAwesomeIcon icon={ faChevronsLeft } />
                </div>
                <div tabIndex={ctx.page > 0 ? 0 : -1} onKeyDown={ (e) => e.key === 'Enter' && prev() } onClick={ prev } className={`${ ctx.page > 0 ? 'cursor-pointer' : 'opacity-25 pointer-events-none' } select-none flex items-center justify-center w-10 h-10`}>
                    <FontAwesomeIcon icon={ faChevronLeft } />
                </div>
                <span className='w-20 text-center'>{ Math.min(ctx.page + 1, totalPages) } / { totalPages }</span>
                <div tabIndex={ctx.page < totalPages - 1 ? 0 : -1} onKeyDown={ (e) => e.key === 'Enter' && next() } onClick={ next } className={`${ ctx.page < totalPages - 1 ? 'cursor-pointer' : 'opacity-25 pointer-events-none' } select-none flex items-center justify-center w-10 h-10`}>
                    <FontAwesomeIcon icon={ faChevronRight } />
                </div>
                <div tabIndex={ctx.page < totalPages - 1 ? 0 : -1} onKeyDown={ (e) => e.key === 'Enter' && goToLast() } onClick={ goToLast } className={`${ ctx.page < totalPages - 1 ? 'cursor-pointer' : 'opacity-25 pointer-events-none' } select-none flex items-center justify-center w-10 h-10`}>
                    <FontAwesomeIcon icon={ faChevronsRight } />
                </div>
            </div>
        </div>
    )
}

export default TablePagination