import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetReleaseNoteQuery, usePutReleaseNoteMutation } from '../../../../../Redux/Api/ReleaseNote';
import BusyArea from '../../../../Shared/BusyArea';
import Button from '../../../../Shared/Form/Button';
import Form from '../../../../Shared/Form/Form';
import Input, { FailedRequirements } from '../../../../Shared/Form/Input';
import Title from '../../../../Shared/Title';
import { Trans, useTranslation } from 'react-i18next';
import StatusPopups from '../../../../Shared/Form/StatusPopups';
import Page404 from '../../../Page404';
import LoadingPage from '../../../LoadingPage';
import { all, max, min } from "../../../../../Utils/InValidation";
import info from "../../../../../Assets/info.svg";
import DropdownInput from "../../../../Shared/Form/DropdownInput";
import ReleaseNotePreview from "./ReleaseNotePreview";
import { faFileDownload, faFloppyDisk, faFileAlt, faNewspaper, faCopy, faCheck } from '@fortawesome/pro-solid-svg-icons';
import Card from '../../../../Shared/Card/Card';
import CardSelect from '../../../../Shared/Card/CardSelect';
import CardGrid from '../../../../Shared/Card/CardGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ReleaseNoteResponseItem = {
    title: string;
    type: string;
    version: string;
    // @ts-ignore
    text: {
        text_nl?: string;
        text_en?: string;
        text_de?: string;
        text_fr?: string;
        text_es?: string;
    };
    // @ts-ignore
    content: {
        content_nl?: string;
        content_en?: string;
        content_de?: string;
        content_fr?: string;
        content_es?: string;
    }
    [key: string]: string | undefined; // Voor dynamische sleutels
};


const ReleaseNoteEditPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { t, i18n } = useTranslation();

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [coppied, setCoppied] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [title, setTitle] = useState('');
    const [type, setType] = useState<string>('');
    const [version, setVersion] = useState('');

    const [title_nl, setTitle_nl] = useState('');
    const [content_nl, setContent_nl] = useState('');

    const [title_en, setTitle_en] = useState('');
    const [content_en, setContent_en] = useState('');

    const [title_de, setTitle_de] = useState('');
    const [content_de, setContent_de] = useState('');

    const [title_fr, setTitle_fr] = useState('');
    const [content_fr, setContent_fr] = useState('');

    const [title_es, setTitle_es] = useState('');
    const [content_es, setContent_es] = useState('');

    if (!id) return <Page404 />;

    const releaseNoteRequest = { id };
    const { data, refetch } = useGetReleaseNoteQuery(releaseNoteRequest);
    const [putOrganisation] = usePutReleaseNoteMutation();

    const languages = ['nl', 'en', 'de', 'fr', 'es'];

    const toolsArray = [
        { title: t('releasenote:toolTitle'), startsWith: '***', endsWith: '***' },
        { title: t('releasenote:toolBold'), startsWith: '**', endsWith: '**' },
        { title: t('releasenote:toolItalic'), startsWith: '*', endsWith: '*' },
        { title: t('releasenote:toolUnderline'), startsWith: '__', endsWith: '__' },
        { title: t('releasenote:toolLine'), static: '--' },
        { title: t('releasenote:toolHasSponsor'), startsWith: '{sponsor (', endsWith: ')}' },
        { title: t('releasenote:toolHasNotify'), startsWith: '{notify (', endsWith: ')}' },
        { title: t('releasenote:toolHasPatrick'), startsWith: '{patrick (', endsWith: ')}' },
        { title: t('releasenote:toolHasScan'), startsWith: '{scan (', endsWith: ')}' },
        { title: t('releasenote:toolHasFamily'), startsWith: '{family (', endsWith: ')}' },
        { title: t('releasenote:toolHasPhoto'), startsWith: '{photo (', endsWith: ')}' },
    ];

    useEffect(() => {
        if (data !== undefined) {
            setTitle(data.title);
            setTitle_nl(releaseNoteData.text.text_nl || '');
            setTitle_en(releaseNoteData.text.text_en || '');
            setTitle_de(releaseNoteData.text.text_de || '');
            setTitle_fr(releaseNoteData.text.text_fr || '');
            setTitle_es(releaseNoteData.text.text_es || '');
            setContent_nl(releaseNoteData.content.content_nl || '');
            setContent_en(releaseNoteData.content.content_en || '');
            setContent_de(releaseNoteData.content.content_de || '');
            setContent_fr(releaseNoteData.content.content_fr || '');
            setContent_es(releaseNoteData.content.content_es || '');
            setType(releaseNoteData.type);
            setVersion(releaseNoteData.version);
        }
    }, [data]);

    const submit = async (data: ReleaseNoteResponseItem) => {
        if (busy) return;
        setSubmitted(true);
        if (FailedRequirements(data, 'title', 'version', 'text_nl', 'text_en', 'text_de', 'text_fr', 'text_es', 'content_nl', 'content_en', 'content_de', 'content_fr', 'content_es')) return setError(t("genericError") || "")
        setBusy(true);
        setError('');
        setSuccessMessage('');

        for (const language of languages) {
            const textKey = `text_${language}`;
            if (data[textKey] && data[textKey]!.length > 150) {
                setError(`${t('organisation:news:tooLong')} (${language.toUpperCase()})`);
                setBusy(false);
                return;
            }
        }

        try {
            const outData = {
                title: data.title,
                type: type ? type : releaseNoteData.type,
                text: {
                    text_nl: data.text_nl || '',
                    text_en: data.text_en || '',
                    text_de: data.text_de || '',
                    text_fr: data.text_fr || '',
                    text_es: data.text_es || '',
                },
                content: {
                    content_nl: data.content_nl || '',
                    content_en: data.content_en || '',
                    content_de: data.content_de || '',
                    content_fr: data.content_fr || '',
                    content_es: data.content_es || '',
                },
                version: data.version,
            };

            await putOrganisation([id, outData]).unwrap();
            setSuccessMessage(t('releasenote:successEdit') as string);
            setTimeout(() => {
                window.location.replace(`/${i18n.language}/dashboard`);
            }, 1000);
        } catch (e) {
            setError(t('modal:error') as string);
        } finally {
            setBusy(false);
        }
    };

    const exportData = () => {
        const exportContent = `
Title: [\n${title}\n]
Type: [\n${type}\n]
Version: [\n${version}\n]
Title (NL): [\n${title_nl}\n]
Content (NL): [\n${content_nl}\n]
Title (EN): [\n${title_en}\n]
Content (EN): [\n${content_en}\n]
Title (DE): [\n${title_de}\n]
Content (DE): [\n${content_de}\n]
Title (FR): [\n${title_fr}\n]
Content (FR): [\n${content_fr}\n]
Title (ES): [\n${title_es}\n]
Content (ES): [\n${content_es}\n]
        `;
        try {
            const blob = new Blob([exportContent], { type: 'text/plain;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `ReleaseNote_${version}.txt`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Export failed:", error);
        }
    };

    const copyPrompt = () => {
        const copyContent = `
Translate the following text into the following languages: Dutch (NL), English (EN), German (DE), French (FR), and Spanish (ES).
Do not remove any special styling like {sponsor()}, --, etc. Keep the formatting exactly as is.
Please output each translation in a separate code block indicate each block with the correct language code(uppercase).

Text to translate:`;

        navigator.clipboard.writeText(copyContent).then(() => {
            setCoppied(true);
            setTimeout(() => {
                setCoppied(false);
            }, 1000);
        })
    };


    if (!data) return <LoadingPage />;

    // Casting data to ReleaseNoteResponseItem to ensure TypeScript recognizes the keys
    const releaseNoteData = data as unknown as ReleaseNoteResponseItem;

    return (
        <>
            <Title text={t('releasenote:changeReleasenote')} textNotBold />
            <BusyArea busy={busy}>
                <div className='flex flex-wrap gap-2 mb-4'>
                    <Button icon={faFileDownload} action={exportData} title={t('releasenote:export')} />
                    <Button icon={coppied ? faCheck : faCopy} action={copyPrompt} title={t('releasenote:copyPrompt')} />
                </div>

                <Form submit={submit}>
                    <StatusPopups setText={setError} type='error' text={error} />
                    <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />
                    <Input onChange={(e) => setTitle((e.target as HTMLInputElement).value)} submitted={submitted ? true : false} required label={t('releasenote:name')} id='title' initial={releaseNoteData.title} />

                    <h1 className='-mb-4'>{t('cards:list:selectType')}<span className='text-red-400'>*</span></h1>
                    <CardGrid cols={2}>
                        <CardSelect
                            title={t('releasenote:typeReleaseNote')}
                            subText={t('releasenote:typeReleaseNoteDescription') + ' ' + t('releasenote:ifNews')}
                            onChange={() => setType('Release note')}
                            isActive={type === 'Release note'}
                            icon={faFileAlt}
                            rounded
                        />
                        <CardSelect
                            title={t('releasenote:typeNews')}
                            subText={t('releasenote:typeNewsDescription')}
                            onChange={() => setType('News')}
                            isActive={type === 'News'}
                            icon={faNewspaper}
                            rounded
                        />
                    </CardGrid>

                    <Input submitted={submitted ? true : false} required label={t('releasenote:version')} id='version' initial={releaseNoteData.version} onChange={(e) => setVersion((e.target as HTMLInputElement).value)} />

                    <div className='p-3 mb-3 bg-white rounded-[0.4rem]'>
                        <div className='mb-2 flex items-center'>
                            <img src={info} className='mr-2' />
                            <h1 className='text-2xl font-bold'>{t('releasenote:howTobuildAReleaseNote')}</h1>
                        </div>
                        <p className='mb-4'><Trans i18nKey="releasenote:howTobuildAReleaseNoteDescription" components={{ br: <br /> }} /></p>
                    </div>

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_nl ? title_nl : '?'} content={content_nl ? content_nl : '?'} />

                    <Input submitted={submitted ? true : false} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(NL)'} id='text_nl' initial={releaseNoteData.text.text_nl || ''} onChange={(e) => setTitle_nl((e.target as HTMLInputElement).value)} />
                    <Input tools={toolsArray} submitted={submitted ? true : false} larger required label={t('releasenote:content') + '(NL)'} id='content_nl' initial={releaseNoteData.content.content_nl || ''} onChange={(e) => setContent_nl((e.target as HTMLInputElement).value)} setText={setContent_nl} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_en ? title_en : '?'} content={content_en ? content_en : '?'} />

                    <Input submitted={submitted ? true : false} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(EN)'} id='text_en' initial={releaseNoteData.text.text_en || ''} onChange={(e) => setTitle_en((e.target as HTMLInputElement).value)} />
                    <Input tools={toolsArray} submitted={submitted ? true : false} larger required label={t('releasenote:content') + '(EN)'} id='content_en' initial={releaseNoteData.content.content_en || ''} onChange={(e) => setContent_en((e.target as HTMLInputElement).value)} setText={setContent_en} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_de ? title_de : '?'} content={content_de ? content_de : '?'} />

                    <Input submitted={submitted ? true : false} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(DE)'} id='text_de' initial={releaseNoteData.text.text_de || ''} onChange={(e) => setTitle_de((e.target as HTMLInputElement).value)} />
                    <Input tools={toolsArray} submitted={submitted ? true : false} larger required label={t('releasenote:text') + '(DE)'} id='content_de' initial={releaseNoteData.content.content_de || ''} onChange={(e) => setContent_de((e.target as HTMLInputElement).value)} setText={setContent_de} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_fr ? title_fr : '?'} content={content_fr ? content_fr : '?'} />

                    <Input submitted={submitted ? true : false} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(FR)'} id='text_fr' initial={releaseNoteData.text.text_fr || ''} onChange={(e) => setTitle_fr((e.target as HTMLInputElement).value)} />
                    <Input tools={toolsArray} submitted={submitted ? true : false} larger required label={t('releasenote:content') + '(FR)'} id='content_fr' initial={releaseNoteData.content.content_fr || ''} onChange={(e) => setContent_fr((e.target as HTMLInputElement).value)} setText={setContent_fr} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_es ? title_es : '?'} content={content_es ? content_es : '?'} />

                    <Input submitted={submitted ? true : false} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(ES)'} id='text_es' initial={releaseNoteData.text.text_es || ''} onChange={(e) => setTitle_es((e.target as HTMLInputElement).value)} />
                    <Input tools={toolsArray} submitted={submitted ? true : false} larger required label={t('releasenote:content') + '(ES)'} id='content_es' initial={releaseNoteData.content.content_es || ''} onChange={(e) => setContent_es((e.target as HTMLInputElement).value)} setText={setContent_es} />

                    <Button iconright icon={faFloppyDisk} title={t('releasenote:save')} />
                </Form>
            </BusyArea>
        </>
    );
};

export default ReleaseNoteEditPage;
