import Title from "../../../Shared/Title";
import StatusPopups from "../../../Shared/Form/StatusPopups";
import React, { useState, useEffect } from "react";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import { usePostSponsorMutation } from "../../../../Redux/Api/Sponsors";
import { useAddSponsorLogoMutation } from "../../../../Redux/Api/Sponsors";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import { all, max, min } from "../../../../Utils/InValidation";
import Input, { FailedRequirements } from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import { useAppSelector } from "../../../../Redux/store";
import { Hydra } from "../../../../Utils/Hydra";
import { SponsorResponse } from "../../../../Redux/Api/Request/Sponsors";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLazyGetSponsorDataQuery } from "../../../../Redux/Api/Organisations";
import { useDeleteSponsorMutation } from "../../../../Redux/Api/Sponsors";
import { faArrowLeft, faFloppyDisk } from "@fortawesome/pro-solid-svg-icons";
import SponsorPreview from "./SponsorPreview";

const SponsorEditPage = () => {

    const org_id = useAppSelector((s) => s.organisation.id)!;
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [busy, setBusy] = useState(false);
    const [getSponsorData, { data }] = useLazyGetSponsorDataQuery();
    const [fileBlob, setFileBlob] = useState('');
    const { id } = useParams();
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [uploadFile] = useUploadMutation();
    const [postSponsor] = usePostSponsorMutation();
    const [addSponsorLogo] = useAddSponsorLogoMutation();
    const [deleteSponsor] = useDeleteSponsorMutation();

    const [sponsorName, setSponsorName] = useState('');
    const [sponsorImage, setSponsorImage] = useState('');

    useEffect(() => {
        if (org_id) {
            getSponsorData({
                id: org_id
            });
        }
    }, [org_id, getSponsorData]);

    useEffect(() => {
        if (data) {
            const sponsor = data.find((sponsor) => sponsor.id === id);
            if (sponsor) {
                setSponsorName(sponsor.name);
                setSponsorImage(sponsor.icon);
            }
        }
    }, [data, id]);

    const sponsor = data?.find((sponsor) => sponsor.id === id);
    const sponsorIcon = sponsor?.icon;
    const sponsorID = sponsor?.id;

    const toMYSQLDate = (datestring: string) => {
        const date = new Date(datestring)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    const submit = async (data: {
        hiddenName: string,
        name: string,
        logo: File,
        activeFrom: string,
        activeUntil: string,

    }) => {
        setSubmitted(true);
        if (data.activeUntil === null || data.activeUntil === '' || data.activeUntil === ' ') {
            data.activeUntil = '9999-01-01';
        }
        if (FailedRequirements(data, 'logo', 'activeFrom', 'activeUntil')) return setError(t("sponsor:error") || "")
        if (busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            let upload_id = ''
            if (data.logo.name || data.logo.name.trim() !== '') {
                upload_id = await uploadFile({
                    file: data.logo
                }).unwrap();
            }

            const outData = {
                hiddenName: data.hiddenName,
                name: data.name,
                icon: sponsorIcon,
                from: toMYSQLDate(data.activeFrom),
                until: toMYSQLDate(data.activeUntil),
                organisation: org_id
            }

            const sponsor_id = await postSponsor([org_id, outData]).unwrap();

            if (data.logo.name || data.logo.name.trim() !== '') {
                const logo_url = await addSponsorLogo({
                    sponsor_id: sponsor_id.toString(),
                    upload_id
                });
            }

            await deleteSponsor(sponsorID as string);

            sessionStorage.setItem('successMessage', t('sponsor:sponsorUpdated') as string);
            setBusy(false)
            navigate(`/${i18n.language}/dashboard/sponsor/list`);
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
            setBusy(false);
        }
    }

    return (
        <>
            {sponsor ? (
                <>
                    <BusyArea busy={busy}>
                        <div className='flex flex-col xl:flex-row'>
                            <div className='w-full'>
                                <Title text={t('sponsor:titleEdit')} subtitle={t('sponsor:subtitleEdit') as string} />
                                <StatusPopups setText={setError} type='error' text={error} />
                                <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />
                                <Form submit={submit}>
                                    <Input
                                        invalidator={all(min(0), max(255))}
                                        sublabel={t('cards:list:optional') as string}
                                        initial={sponsor.hiddenName}
                                        placeholder=""
                                        label={t('sponsor:hiddenName')}
                                        id='hiddenName' />

                                    <Input
                                        onChange={(e) => setSponsorName((e.target as HTMLInputElement).value)}
                                        invalidator={all(min(0), max(255))}
                                        sublabel={t('cards:list:optional') as string}
                                        initial={sponsor.name}
                                        placeholder=""
                                        label={t('sponsor:name')}
                                        id='name' />

                                    <FilePicker required submitted={submitted ? true : false} id='logo' label={t('sponsor:logo')} onChangeBlob={(blob) => { setFileBlob(blob); setSponsorImage(blob); }} accept={['image/*']} initial={sponsor.icon} />

                                    <CalendarInput
                                        required
                                        submitted={submitted ? true : false}
                                        initial={sponsor.from}
                                        label={t('sponsor:activeFrom')}
                                        id='activeFrom' />
                                    <CalendarInput
                                        sublabel={t('cards:list:optional') as string}
                                        label={t('sponsor:activeUntil')}
                                        initial={sponsor.until}
                                        id='activeUntil' />
                                    <div className='flex flex-wrap mt-5 gap-4'>
                                        <Button icon={faFloppyDisk} iconright title={t('sponsor:save')} />
                                        <Button icon={faArrowLeft} action={() => navigate(`/${i18n.language}/dashboard/sponsor/list`)} title={t('back')} />
                                    </div>
                                </Form>
                            </div>
                            <div className='-m-32 sm:m-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 flex justify-center xl:justify-end mb-0 sm:mb-10'>
                                <SponsorPreview title={sponsorName} image={sponsorImage} />
                            </div>
                        </div>
                    </BusyArea>
                </>
            ) : (null)}
        </>
    );
}

export default SponsorEditPage;
