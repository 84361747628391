import { useEffect, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLazyGetOrganisationTypesQuery, useGetNotificationsQuery } from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store';
import Button from "../../../Shared/Form/Button";
import Title from '../../../Shared/Title';
import Table from "../../../Shared/Table/Table";
import Column from "../../../Shared/Table/Column";
import Header from "../../../Shared/Table/Header";
import Row from "../../../Shared/Table/Row";
import Data from "../../../Shared/Table/Data";
import { faArrowLeft, faMobile, faXmark, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../LoadingPage";
import { ModalContext } from '../../../Shared/Modal/Modal';
import { useMediaQuery } from '@mui/material';
import { JWT } from '../../../../Utils/JWT';
import { Hydra } from '../../../../Utils/Hydra';
import BusyArea from '../../../Shared/BusyArea';
import StatusPopups from '../../../Shared/Form/StatusPopups';
import { useDeleteNotificationMutation } from "../../../../Redux/Api/Notifications";

interface Notification {
    id: string;
    title: string;
    data: {
        url: string;
    }
    text: string;
    sentAt: {
        date: string;
    };
    type: string;
    isSent: boolean;
    scheduledAt: {
        date: string
    }
}

const NotifyHistoryPage = () => {
    const { t, i18n } = useTranslation();
    const org_id = useAppSelector((s) => s.organisation.id);
    const { data = [], isFetching, refetch } = useGetNotificationsQuery(org_id!, {
        skip: !org_id,
    }) as { data?: Notification[]; isFetching: boolean; refetch: () => void };
    const navigate = useNavigate();
    const { withModal } = useContext(ModalContext);
    const [getTypes, { data: types }] = useLazyGetOrganisationTypesQuery()
    const isSmallScreen = useMediaQuery('(min-width: 500px)');
    const isMediumScreen = useMediaQuery('(min-width: 900px)');
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [deleteNotification] = useDeleteNotificationMutation();

    const roles = JWT.read<Array<string>>('roles') || [];
    const isAdmin = roles.includes('ROLE_SUPER_ADMIN');

    useEffect(() => {
        if (org_id) {
            refetch();
        }
    }, [org_id, refetch]);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedTime = date.toTimeString().split(' ')[0].slice(0, 5);
        return `${day}-${month}-${year} ${t('at')} ${formattedTime}`;
    };

    const handleDelete = async (id: string) => {
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await deleteNotification(id);

            if (org_id)
                refetch()
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
        }

        setBusy(false)
    }

    useEffect(() => {
        if (org_id)
            getTypes(org_id)
    }, [org_id])

    if (!org_id || isFetching || !data || !types) { return <LoadingPage />; }

    return (
        <>
            <Title text={t('notification:history')} subtitle={t('notification:historySubtitle') as string} />
            <StatusPopups setText={setError} type='error' text={error} />
            <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />
            <BusyArea busy={busy}>
            <div className="mb-2 flex gap-4">
                <Button
                    action={() => navigate(`/${i18n.language}/dashboard/notify`)}
                    className="my-6"
                    title={t('notification:backToNotify')}
                    icon={faArrowLeft}
                />
            </div>
            <Table
                withSearch
                className="mb-10"
            >
                <Header>
                    {isAdmin && <Column noSort name='' />}
                    <Column hidden={!isSmallScreen} name={t('notification:tableTitle')} />
                    <Column hidden={!isSmallScreen} name={t('notification:tableLink')} />
                    <Column hidden={!isMediumScreen} name={t('notification:tableType')} />
                    <Column initialSort={'up'} name={t('notification:tableDate')} />
                </Header>
                    {isAdmin ? (
                        <Data>
                            {data.map(({ id, title, data, text, sentAt, type, isSent, scheduledAt }, i) => (
                                <Row
                                    firstButton='action'
                                    lastButton='deleteAction'
                                    key={id}
                                    index={i}
                                    indexKey={id}
                                    actionDisabled={!data.url}
                                    deleteAction={() => handleDelete(id)}
                                    action={
                                        data.url
                                            ? withModal(
                                                { title: t('notification:openLink'), body: data.url },
                                                () => window.open(data.url, '_blank')
                                            )
                                            : () => { }
                                    }
                                    customIcon={isSent ? faCheck : faXmark}
                                    iconHover={t(isSent ? 'notification:isSend' : 'notification:isNotSend') as string}
                                    className={`first:font-bold ${isSent ? 'first:text-green-500' : 'first:text-red-500 '}`}

                                    specialActionIcon={faMobile}
                                    specialAction={() => navigate(`/${i18n.language}/dashboard/notify`, { state: { news: { title: title, url: data.url, text: text, type: type } } })}
                                    data={
                                        !isMediumScreen ?
                                            !isSmallScreen ? [
                                                isSent ? formatDate(sentAt.date) : t('notification:datePlanned', { date: formatDate(scheduledAt.date) })
                                            ] : [
                                                title,
                                                data.url || '-',
                                                isSent ? formatDate(sentAt.date) : t('notification:datePlanned', { date: formatDate(scheduledAt.date) })
                                            ] : [
                                                title,
                                                data.url || '-',
                                                type === 'all' ? t('notification:checkbox') : types.find((t) => t.id === type)?.name || t('notification:typeDeleted'),
                                                isSent ? formatDate(sentAt.date) : t('notification:datePlanned', { date: formatDate(scheduledAt.date) })
                                            ]
                                    }
                                />
                            ))}
                        </Data>
                    ) : (
                        <Data>
                            {data.map(({ id, title, data, text, sentAt, type, isSent, scheduledAt }, i) => (
                                <Row
                                    firstButton='action'
                                    lastButton='specialAction'
                                    key={id}
                                    index={i}
                                    indexKey={id}
                                    actionDisabled={!data.url}
                                    action={
                                        data.url
                                            ? withModal(
                                                { title: t('notification:openLink'), body: data.url },
                                                () => window.open(data.url, '_blank')
                                            )
                                            : () => { }
                                    }
                                    specialActionIcon={faMobile}
                                    specialAction={() => navigate(`/${i18n.language}/dashboard/notify`, { state: { news: { title: title, url: data.url, text: text, type: type } } })}
                                    data={
                                        !isMediumScreen ?
                                            !isSmallScreen ? [
                                                isSent ? formatDate(sentAt.date) : t('notification:datePlanned', { date: formatDate(scheduledAt.date) })
                                            ] : [
                                                title,
                                                data.url || '-',
                                                isSent ? formatDate(sentAt.date) : t('notification:datePlanned', { date: formatDate(scheduledAt.date) })
                                            ] : [
                                                title,
                                                data.url || '-',
                                                type === 'all' ? t('notification:checkbox') : types.find((t) => t.id === type)?.name || t('notification:typeDeleted'),
                                                isSent ? formatDate(sentAt.date) : t('notification:datePlanned', { date: formatDate(scheduledAt.date) })
                                            ]
                                    }
                                />
                            ))}
                        </Data>
                    )}
            </Table>
            </BusyArea>
        </>
    );
};

export default NotifyHistoryPage;
