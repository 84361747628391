import { useTranslation } from "react-i18next";
import { DeviceFrameset } from 'react-device-frameset';
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faShareNodes, faSquareList } from "@fortawesome/pro-light-svg-icons";
import iconanimationred from "../../../Assets/iconanimationred.gif";
import { useAppSelector } from '../../../Redux/store';
import MyCard from "./MyCard";
import News from "./News";
import Socials from "./Socials";
import { motion, AnimatePresence } from "framer-motion";
import digipaslogo from "../../../Assets/digipaslogo.png";
import { faAdd } from "@fortawesome/pro-light-svg-icons";

interface MobileAppProps {
    activeItem: 'mycard' | 'news' | 'socials';
    children?: React.ReactNode;
    customIcon?: string;
    customColor?: boolean;
    customColorPrimary?: string;
    customColorSecondary?: string;
    fullOverlay?: boolean;
    showDefaultPage?: boolean;
    allowMovement?: boolean;
    disableAnimation?: boolean;
}

const MobileApp: React.FC<MobileAppProps> = ({ activeItem, children, customIcon, customColor, customColorPrimary, customColorSecondary, fullOverlay, showDefaultPage, allowMovement, disableAnimation }) => {
    const { t } = useTranslation();
    const [fileBlob, setFileBlob] = useState<string>('');
    const theme = useAppSelector((s) => s.organisation.settings?.theme);
    const [isActive, setIsActive] = useState(activeItem);
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        if (!theme) return;
        setFileBlob(theme.icon);
    }, [theme]);

    return (
        <motion.div initial={{ y: disableAnimation ? 0 : 150, opacity: disableAnimation ? 1 : 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
            {customColor && (
                <style>{`
                    :root {
                        --color-accent: ${customColorPrimary};
                        --color-accent-light: ${customColorSecondary};
                    }
                `}</style>
            )}
            <DeviceFrameset device="iPhone X" color="black" style={{ width: "428px", height: "767px", overflow: "hidden", position: "relative" }}>
                <motion.div animate={{ scale: menu ? 0.95 : 1 }} initial={{ scale: 1 }} transition={{ duration: 0.2, ease: "easeInOut" }} style={{ transformOrigin: 'center' }} tabIndex={-1} className={`${fullOverlay && 'blur-sm'} font-app w-full h-full overflow-x-hidden`}>
                    <div onClick={() => setMenu(true)} className="cursor-pointer bg-white p-5 pb-3">
                        <img src={iconanimationred} className="mt-7 ml-4 h-6" />
                    </div>

                    <div className='pointer-events-none absolute left-[147px] top-[40px] w-[80px] h-[80px] flex justify-center items-center z-10'>
                        {(fileBlob || customIcon) && (
                            <img onClick={() => setMenu(true)} className='pointer-events-auto cursor-pointer max-w-[80px] max-h-[80px]' src={customIcon || fileBlob} alt='Logo' />
                        )}
                    </div>

                    <div tabIndex={-1} className={`bg-[#F6F8FA] w-full h-[80%] ${activeItem === 'mycard' && isActive === 'mycard' ? 'overflow-y-hidden' : 'overflow-y-auto'} overflow-x-hidden no-scrollbar`}>
                        {showDefaultPage && !allowMovement && activeItem === 'mycard' && <MyCard />}
                        {showDefaultPage && !allowMovement && activeItem === 'news' && <News />}
                        {showDefaultPage && !allowMovement && activeItem === 'socials' && <Socials />}

                        {allowMovement && isActive === 'mycard' && (activeItem !== 'mycard' ? <MyCard /> : <>{children}</>)}
                        {allowMovement && isActive === 'news' && (activeItem !== 'news' ? <News /> : <>{children}</>)}
                        {allowMovement && isActive === 'socials' && (activeItem !== 'socials' ? <Socials /> : <>{children}</>)}

                        {!fullOverlay && !allowMovement && <>{children}</>}
                    </div>

                    <div className="bg-white border-t-[3px] border-gray-100 mt-12 mx-[-10px] flex justify-center absolute bottom-0 w-[calc(100%+20px)]">
                        <div className={`text-[15px] w-1/3 whitespace-nowrap flex justify-center`}>
                            <div onClick={() => { allowMovement && setIsActive('mycard') }} className={`py-3 w-[63%] flex flex-col items-center border-t-[2px] text-[12px] gap-2 ${allowMovement ? 'cursor-pointer' : 'cursor-not-allowed'} ${isActive === 'mycard' ? 'border-accent text-accent' : 'hover:text-accent-light hover:border-accent-light hover:opacity-100 border-transparent opacity-50'}`}>
                                <FontAwesomeIcon icon={faQrcode} className="text-2xl" />
                                {t("cards:app:mycard")}
                            </div>
                        </div>
                        <div className={`text-[15px] w-1/3 whitespace-nowrap flex justify-center`}>
                            <div onClick={() => { allowMovement && setIsActive('news') }} className={`py-3 w-[63%] flex flex-col items-center border-t-[2px] text-[12px] gap-2 ${allowMovement ? 'cursor-pointer' : 'cursor-not-allowed'} ${isActive === 'news' ? 'border-accent text-accent' : 'hover:text-accent-light hover:border-accent-light hover:opacity-100 border-transparent opacity-50'}`}>
                                <FontAwesomeIcon icon={faSquareList} className="text-2xl" />
                                {t("cards:app:news")}
                            </div>
                        </div>
                        <div className={`text-[15px] w-1/3 whitespace-nowrap flex justify-center`}>
                            <div onClick={() => { allowMovement && setIsActive('socials') }} className={`py-3 w-[63%] flex flex-col items-center border-t-[2px] text-[12px] gap-2 ${allowMovement ? 'cursor-pointer' : 'cursor-not-allowed'} ${isActive === 'socials' ? 'border-accent text-accent' : 'hover:text-accent-light hover:border-accent-light hover:opacity-100 border-transparent opacity-50'}`}>
                                <FontAwesomeIcon icon={faShareNodes} className="text-2xl" />
                                {t("cards:app:socials")}
                            </div>
                        </div>
                    </div>
                </motion.div>

                <AnimatePresence>
                    {menu && (
                        <motion.div initial={{ opacity: 0, scale: 1.05 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 1.05 }} transition={{ duration: 0.2, ease: "easeInOut" }} style={{ transformOrigin: 'center' }} tabIndex={-1} className={`${fullOverlay && 'blur-sm'} absolute top-0 font-app w-full h-full overflow-x-hidden bg-[#F6F8FA]`}>
                            <div className="bg-white p-5 pb-3">
                                <img src={digipaslogo} className="mt-7 ml-4 h-6" />
                            </div>

                            <h1 className="ml-6 mt-3 mb-2 text-[28px] font-app-bold">Digipas.app</h1>

                            <div className='flex ml-6 gap-4 flex-wrap'>
                                <div onClick={() => setMenu(false)} className='cursor-pointer flex items-center justify-center w-[100px] h-[100px] rounded-[10px] bg-white'>
                                    {(fileBlob || customIcon) && <img className='max-w-[80px] max-h-[80px]' src={customIcon || fileBlob} alt='Logo' />}
                                </div>
                                <div className='cursor-not-allowed flex items-center justify-center w-[100px] h-[100px] rounded-[10px] bg-white text-[#6A6A6A]'>
                                    <FontAwesomeIcon className='w-[25px] h-[25px]' icon={faAdd} />
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {fullOverlay && <>{children}</>}
            </DeviceFrameset>
        </motion.div>
    );
};

export default MobileApp;
