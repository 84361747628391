import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';

export interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title?: any;
    outline?: boolean;
    icon?: IconDefinition;
    rounded?: boolean;
    hideOverflow?: boolean;
    noImgFade?: boolean;
}

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {

    const { title, outline, children, icon, rounded, hideOverflow, noImgFade, ...restProps } = props;

    return (
        <div
            ref={ref}
            {...restProps}
            className={`flex flex-col relative p-3 ${props.onClick ? 'cursor-pointer' : ''} ${hideOverflow ? 'overflow-hidden' : ''} ${icon ? 'group' : ''} ${outline ? 'border-[1px] border-light-250' : 'bg-light-100'} ${restProps.className}`}
            style={{
                border: '1px solid #D7E3F2',
                borderRadius: rounded ? '0.4rem' : '0',
            }}
        >
            {title && (
                <div className='text-lg flex justify-between z-[2] px-3 pt-2' style={{ fontSize: '16px', color: '#5B6C79' }}>{title}</div>
            )}

            {children}

            {icon && 
            <>
                <FontAwesomeIcon className='absolute -bottom-4 left-2 -rotate-12 text-black opacity-[4%] text-[150px] mt-1.5 transition group-hover:scale-125 group-focus:scale-125' icon={icon} />
                {!noImgFade && <div className={`absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-white to-transparent`} />}
            </>
            }
        </div>
    );
});

export default Card;
