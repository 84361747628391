import FilePicker, { FilePickerHandle } from "./FilePicker";
import Card from "../Card/Card";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "./Button";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import constants from "../../../Constants";
import { AnimatePresence, motion } from "framer-motion";
import Title from "../Title";
import * as React from "react";
import { Person } from "@mui/icons-material";
import { faImage, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

export interface ImageInputProps {
    onCrop: (blob: Blob | null) => void,
    image?: string,
    onDelete?: () => void,
    withSave?: boolean
}

const ImageInput = (props: ImageInputProps) => {
    const { image, onCrop, onDelete } = props

    const { t } = useTranslation()
    const [fileBlob, setFileBlob] = useState('')
    const [fileName, setFileName] = useState('')
    const [upload, setUpload] = useState('')
    const [cropModal, setCropModal] = useState(false)

    const filePickerRef = useRef<FilePickerHandle | null>(null)
    const cropperRef = useRef<ReactCropperElement>(null)

    const [isModified, setIsModified] = useState(false);

    const removeImage = () => {
        if (fileBlob) {
            filePickerRef.current?.clearInput()
            setFileBlob('')
            if (onDelete) onDelete()
            setIsModified(true);
        }
    }

    const cancelCrop = () => {
        setCropModal(false)
        filePickerRef.current?.clearInput()
        setUpload('')
    }

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper
        if (typeof cropper === 'undefined') {
            return
        }

        cropper.getCroppedCanvas().toBlob((blob) => {
            //@ts-ignore
            blob.name = fileName
            onCrop(blob)
        })

        setFileBlob(cropper.getCroppedCanvas().toDataURL())
        setCropModal(false)
        setIsModified(true);
    }

    const getFileName = (file: File) => {
        if (file) {
            setFileName(file.name)
        }
    }

    useEffect(() => {
        if (upload) {
            setCropModal(true)
        }
    }, [upload])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && cropModal) {
                cancelCrop();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [cropModal])

    useEffect(() => {
        if (image) {
            fetch(`${constants.API_URL}${image}`, {
                method: 'GET',
            })
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setFileBlob(reader.result as string);
                        setIsModified(false);
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(error => {
                    console.error('Error fetching the image:', error);
                    setFileBlob('');
                });
        } else {
            setFileBlob('');
        }
    }, [image]);

    return (
        <>
            <Card rounded className='flex flex-col pb-1' title={t('cards:list:photo')}>
                <div className='flex-grow flex items-center'>
                    {fileBlob ? (
                        <img className='border' style={{ width: 120, height: 120, marginTop: 12, borderRadius: 8 }} src={fileBlob} />
                    ) : (
                        <Person style={{ width: 120, height: 120, marginTop: 12, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray' }} />
                    )}
                    <div className='ml-1 sm:ml-4 mt-3 gap-4 flex flex-col justify-end h-[120px]'>
                        {props.withSave && (<Button icon={faImage} disabled={!isModified} title={t('cards:edit:saveImg')} />)}
                        <Button icon={faTrashAlt} title={t('cards:list:delete')} disabled={!fileBlob} nosubmit action={() => removeImage()} />
                    </div>
                </div>
                <div className='mt-2'>
                    <FilePicker noBottomMargin className="mb-[6px]" ref={filePickerRef} label='' id='image' accept={['image/*']} onChangeBlob={setUpload} onChange={getFileName} />
                </div>
            </Card>
            {cropModal && <style>{`body { overflow: hidden; }`}</style>}
            <AnimatePresence>
                {cropModal ?
                    <div className='fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-[10000]'>
                        <motion.div
                            initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                            exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            className='rounded-[0.4rem] flex flex-col z-50 bg-light-200'
                        >
                            <div className='w-[250px] md:w-auto m-10'>
                                <Title text={t('cards:edit:crop')} />
                                <Cropper
                                    className='m-10 h-[200px] w-[200px] md:h-[400px] md:w-[400px]'
                                    src={upload}
                                    aspectRatio={1}
                                    zoomable={false}
                                    ref={cropperRef}
                                />
                                <div className='flex gap-4 mt-2 float-right'>
                                    <Button action={handleCrop} nosubmit title={t('cards:edit:save')} />
                                    <Button action={cancelCrop} nosubmit title={t('cards:edit:cancel')} />
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            key="background-div"
                            onClick={() => cancelCrop()}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.3 }}
                            exit={{ opacity: 0 }}
                            className='fixed left-0 top-0 w-screen h-screen bg-black'
                        />
                    </div>
                    : null}
            </AnimatePresence>
        </>
    )
}

export default ImageInput
