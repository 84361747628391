import React, { ReactNode, useState, useEffect } from "react";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useTranslation } from "react-i18next";

export type DataGridToolbarAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type DataGridToolbarProps = {
    actions?: Array<DataGridToolbarAction>,
    style?: React.CSSProperties,
    select?: any
    selectAll?: any,
    onSelectAll?: () => void,
    data?: any
};

export default function DataGridToolbar({ actions, style, data, selectAll, onSelectAll, select }: DataGridToolbarProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (select < 1) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [select]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        event.preventDefault();
        if (!actions) return;
        if (event.key === "ArrowDown") {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % actions.length);
        } else if (event.key === "ArrowUp") {
            if (highlightedIndex === -1) { setHighlightedIndex(actions.length); }
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + actions.length) % actions.length);
        } else if (event.key === "Enter" && highlightedIndex >= 0) {
            actions[highlightedIndex].onClick();
            setTimeout(() => {
                handleClose();
            }, 100);
        } else if (event.key === "Escape" || event.key === "Tab") {
            handleClose();
        }
    };

    return (
        <GridToolbarContainer>
            {actions && actions.length > 0 && (
                <div className="py-2 flex items-center">
                    <Button className={`${open ? 'bg-gray-300' : 'bg-white'} hover:bg-blue-500 text-black hover:text-white py-2 rounded-[0.4rem] overflow-hidden`} disabled={disabled} onClick={handleClick} size="small" variant="contained"
                        sx={{
                            ml: 1, mr: 2,
                            ...(open && {
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: 0,
                                    left: '50%',
                                    transform: 'translateX(-50%) translateY(50%) rotate(45deg)',
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'white',
                                    border: '1px solid #D7E3F2',
                                },
                            }),
                        }}>
                        <KeyboardArrowDownOutlinedIcon sx={{ mr: 1 }} className={`${open && ('rotate-180')}`} /> {t('datagrid:actions')}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        onKeyDown={handleKeyDown}
                        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                        autoFocus={false}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: '0.4rem',
                                border: '1px solid #D7E3F2',
                            },
                        }}
                    >
                        {actions.map((action, index) => (
                            <MenuItem key={action.label} onClick={() => action.onClick()} className={`h-8 px-5 my-1 ${highlightedIndex === index ? 'bg-blue-50' : ''}`}>
                                <div className="flex items-center w-full border-b-[1px] border-[#dbdbdb]">
                                    {action.icon && (
                                        <ListItemIcon sx={{ minWidth: 32 }}>
                                            {action.icon}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText className="py-1 px-1">
                                        {action.label}
                                    </ListItemText>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>

                    {selectAll && (
                        <button
                            style={{ overflow: 'hidden', border: '1px solid #D7E3F2', fontFamily: 'Inter' }}
                            className='bg-white rounded-[0.4rem] hover:bg-blue-500 text-black hover:text-white py-2 px-3'
                            onClick={onSelectAll}
                        >
                            {select >= data ? t('datagrid:deselectAll') : t('datagrid:selectAll')}
                        </button>
                    )}
                    <button
                        style={{ overflow: 'hidden', border: '1px solid #D7E3F2', fontFamily: 'Inter' }}
                        className='absolute pointer-events-none left-[280px] bg-brand opacity-0 focus:opacity-100 rounded-[0.4rem] text-white hover:text-white py-2 px-3'
                        onClick={() => { window.location.hash = 'skipTable'; }}
                    >
                        {t('skipTable')}
                    </button>

                </div>
            )}
        </GridToolbarContainer>
    );
}
