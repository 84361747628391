import Api from "./Api";

// all other notification calls are in Organisations API

const notificationApi = Api.injectEndpoints({
    endpoints: build => ({
        deleteNotification: build.mutation<void, string>({
            query: (id: string) => ({
                url: `api/notification/${id}`,
                method: 'DELETE',
            })
        }),
    }),
});

export const {
    useDeleteNotificationMutation,
} = notificationApi;
export default notificationApi;
