import { AnimatePresence, motion } from 'framer-motion';
import React, { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { Strings } from '../../../Strings/nl';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faMagicWandSparkles } from '@fortawesome/pro-solid-svg-icons';

export function FailedRequirements(obj: any, ...compare: Array<string>): boolean {
    for (let c of compare) {
        if (!obj[c]) {
            const element = document.getElementById(c);
            if (element && element instanceof HTMLInputElement && (element.type === 'hidden' || element.type === 'file')) {
                const elementFocus = document.getElementById(c + '[focus_element]');

                if (elementFocus) {
                    elementFocus.focus();
                }
            } else if (element) {
                element.focus();
            }
            return true;
        }
    }
    return false;
}

export interface InputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label: string,
    sublabel?: string,
    id?: string,
    type?: HTMLInputTypeAttribute,
    initial?: string,
    required?: boolean,
    large?: boolean,
    larger?: boolean,
    value?: string,
    placeholder?: string,
    valueChange?: (value: string, valid: boolean) => void,
    invalidator?: (value: string) => false | string,
    submitted?: boolean,
    tools?: Array<any>;
    setText?: (text: string) => void
    autoComplete?: boolean
}

const Input = (props: InputProps) => {
    const { label, type, required, value: inValue, className, initial, placeholder, large, larger, valueChange, sublabel, invalidator, id, submitted, tools, setText, autoComplete, ...restProps } = props;

    const labelOrId = id || label;
    const { t } = useTranslation();

    const [value, setValue] = useState(initial || '');
    const [isNotValid, setIsNotValid] = useState<boolean | string>(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => void (inValue != undefined && setValue(inValue)), [inValue]);

    const defaultInValidator = () => false;

    const removeNonNumeric = (input: string): string => {
        return input.replace(/\D/g, '');
    };

    const onChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = evt.currentTarget.value;

        if (type === 'number') {
            value = removeNonNumeric(value);
        }

        let invalidatorReturn = (invalidator || defaultInValidator)(value);

        let validity: string | undefined;
        let number = '';

        if (required && value.length === 0) {
            if (type === 'number') {
                validity = t('onlyNumbersAllowed') || undefined;
            } else {
                validity = t('noInput') || undefined;
            }

        } else if (type === 'url' && value.length > 0 && !value.startsWith('https://') && !value.startsWith('http://')) {
            validity = t('modal:urlError') as string;

        } else if (type === 'email' && value.length > 0) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                validity = t('missingDomain') as string;
            }

        } else {
            if (typeof invalidatorReturn === 'string' && invalidatorReturn.includes('langer')) {
                number = removeNonNumeric(invalidatorReturn);
                validity = t('minLength', { min: number }) || undefined;
            } else if (typeof invalidatorReturn === 'string' && invalidatorReturn.includes('kleiner')) {
                number = removeNonNumeric(invalidatorReturn);
                validity = t('maxLength', { max: number }) || undefined;
            } else {
                invalidatorReturn = (invalidator || defaultInValidator)(value);
            }
        }

        setIsNotValid(validity || false);

        setValue(value);

        if (valueChange) {
            valueChange(value, !validity && (required ? value.length > 0 : true));
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const applyTool = (tool: any) => {
        const input = document.getElementById(labelOrId) as HTMLInputElement | HTMLTextAreaElement;
        let validity: string | undefined = undefined;
        if (!input) return;

        const start = input.selectionStart || 0;
        const end = input.selectionEnd || 0;
        const selectedText = value.substring(start, end);
        let newText = '';

        if (tool.static) {
            newText = value.slice(0, start) + '\n' + tool.static + '\n' + value.slice(end);
        } else {
            newText = value.slice(0, start) + tool.startsWith + selectedText + tool.endsWith + value.slice(end);
        }

        setValue(newText);
        if (setText) {
            setText(newText);
        }

        setTimeout(() => {
            input.focus();
            if (tool.static) {
                input.setSelectionRange(start + tool.static.length + 2, start + tool.static.length + 2);
            } else {
                input.setSelectionRange(start + tool.startsWith.length, start + tool.startsWith.length + selectedText.length);
            }
        }, 0);
    };


    return (
        <div {...restProps} className={`flex flex-col mb-6 ${className}`}>
            <label htmlFor={labelOrId}>
                {label}{required && <span className='text-red-400'>*</span>} {sublabel && <span className='opacity-70 text-sm'>({sublabel})</span>}
            </label>
            {tools && (
                <div className="flex flex-wrap items-center gap-3 p-4 rounded-[0.4rem] mb-1 border border-gray-300">
                    <div className="flex items-center gap-2 text-gray-700">
                        <FontAwesomeIcon icon={faMagicWandSparkles} className="text-blue-500 text-lg" />
                        <h1 className="text-lg font-semibold">{t('tools')}:</h1>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {tools.map((tool, index) => (
                            <button
                                key={index}
                                type="button"
                                className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-blue-100 hover:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 transition-all"
                                onClick={() => applyTool(tool)}
                            >
                                {tool.title}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            <div className={`flex flex-grow mt-1 rounded-[0.4rem] border-[1px] ${submitted && !value || submitted && isNotValid ? 'border-red-500' : 'border-[#D2DCEA]'}`}>
                {(!large && !larger) && (
                    <div className="relative flex flex-row w-full">
                        <input
                            autoComplete={autoComplete ? 'on' : 'off'}
                            placeholder={placeholder}
                            className="flex bg-transparent w-full p-2 px-4 rounded-[0.4rem]"
                            value={value}
                            onChange={onChange}
                            type={type === 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
                            name={labelOrId}
                            id={labelOrId}
                        />
                        {type === 'password' && (
                            <div className={`w-[50px] flex items-center justify-center text-gray-600`}>
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                        )}
                    </div>
                )}
                {(large || larger) && (
                    <textarea
                        placeholder={placeholder}
                        className={`flex flex-grow bg-transparent p-1 ${larger ? 'min-h-[25rem]' : 'min-h-[8rem]'} rounded-[0.4rem]`}
                        value={value}
                        onChange={onChange}
                        name={labelOrId}
                        id={labelOrId}
                    />
                )}
            </div>
            <input name={'@VALID@' + labelOrId} type="hidden" value={!isNotValid + ''} />
            <div className='relative'>
                <AnimatePresence>
                    {(invalidator && isNotValid) && (
                        <motion.div initial={{ height: 0 }} animate={{ height: '1.2rem' }} exit={{ height: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }} className={`absolute text-sm overflow-hidden text-red-400 mb-2`}>
                            {isNotValid || '\u00a0'}
                        </motion.div>
                    )}
                    {(submitted && !value && !isNotValid) && (
                        <motion.div initial={{ height: 0 }} animate={{ height: '1.2rem' }} exit={{ height: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }} className={`absolute text-sm overflow-hidden text-red-400 mb-2`}>
                            {t('required')}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Input;
