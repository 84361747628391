import { faRightFromBracket, faUser } from '@fortawesome/pro-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import { OrganisationResponseItem } from '../../../Redux/Api/Request/Organisations';
import Button from '../Form/Button';
import { logout } from "../../../Redux/State/Auth";
import Dropdown from '../Form/Dropdown';
import { useLazyGetOrganisationQuery, useLazyGetOrganisationsQuery } from '../../../Redux/Api/Organisations';
import { JWT } from '../../../Utils/JWT';
import { setOrganisation } from '../../../Redux/State/Organisation';
import { useAppDispatch, useAppSelector } from '../../../Redux/store';
import { useNavigate } from 'react-router-dom';
import { ModalContext } from '../Modal/Modal';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from "react-i18next";
import Loader from '../Loader';
import Switch from '@mui/material/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface SidebarUserProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onVisibilityChange?: (visibilityMode: boolean) => void;
}

const SidebarUser = (props: SidebarUserProps) => {
    const org_id = useAppSelector((s) => s.organisation.id);
    const { t, i18n } = useTranslation();
    const modal = useContext(ModalContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const organisation_name = JWT.read<string>('organisation_name');
    const full_name = JWT.read<string>('given_name') ? JWT.read<string>('given_name')! + ' ' + JWT.read('family_name')! : '';
    const [getOrganisations, { data: organisations }] = useLazyGetOrganisationsQuery();
    const [getOrganisation] = useLazyGetOrganisationQuery();
    const [activeOrganisation, setActiveOrganisation] = useState<OrganisationResponseItem>();
    const hidden = { hidden: 'true' };
    const [visibilityMode, setVisibilityMode] = useState(false);
    const [busy, setBusy] = useState(false);

    const changeVisibility = () => {
        const newVisibility = !visibilityMode;
        setVisibilityMode(newVisibility);
        if (props.onVisibilityChange) {
            props.onVisibilityChange(newVisibility);
        }
    };

    useEffect(() => {
        if (!JWT.read('organisation_id')) getOrganisations(hidden);
        else setFirstOrg(JWT.read('organisation_id')!);
    }, []);

    useEffect(() => {
        if (!organisations) return;
        let organisationId = organisations![0].id;
        if (localStorage) {
            const checkOrganisation = localStorage.getItem('active_organisation');
            if (checkOrganisation) {
                organisationId = checkOrganisation;
            }
        }
        if (!org_id) setFirstOrg(organisationId);
    }, [organisations]);

    const setFirstOrg = (id: string) => {
        if (id === org_id) return;
        localStorage.setItem('active_organisation', id);
        getOrganisation({ id }).then((data) => {
            if (org_id) navigate(`/${i18n.language}/dashboard`);
            setActiveOrganisation(data.data!);
            dispatch(setOrganisation(data.data!));
        });
    };

    const setOrg = async (id: string) => {
        if (id === org_id) return;
        if (!(await modal.show(t('admin:switchOrganizationWarningTitle'), t('admin:switchOrganizationWarningMessage')))) {
            modal.hide();
            return;
        }
        modal.hide();
        localStorage.setItem('active_organisation', id);
        getOrganisation({ id }).then((data) => {
            if (org_id) setBusy(true);
            setActiveOrganisation(data.data!);
            dispatch(setOrganisation(data.data!));
            sessionStorage.removeItem('notificationAccess');
            sessionStorage.removeItem('sponsorAccess');
            sessionStorage.removeItem('patrickAssistantAccess');
            setTimeout(() => {
                window.location.reload();
            }, 500);
        });
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const closeDropdown = () => {
        const dropdownToggleEvent = new CustomEvent('dropdownToggle', {
            detail: { isOpen: false }
        });
        window.dispatchEvent(dropdownToggleEvent);
    }

    return (
        <>
            {busy && (
                <div className='absolute top-0 left-0 w-screen h-screen z-[99999999] flex justify-center items-center bg-white'>
                    <Loader />
                </div>
            )}
            <div {...props} className='flex flex-col gap-4 px-6 mt-auto my-4 relative'>
                <div className='flex flex-col'>
                    {!visibilityMode ? (
                        organisations ? (
                            <Dropdown
                                onFocus={closeDropdown}
                                className="mt-2"
                                noMarginBottom
                                up
                                large
                                onChange={setOrg}
                                initial={org_id}
                                label={t('sidebar:organisations')}
                                options={Object.fromEntries(
                                    organisations.map((org) => [org.id, org.name])
                                )}
                            />
                        ) : null
                    ) : (
                        activeOrganisation && (
                            <div className='flex text-lg'>{activeOrganisation.name}</div>
                        )
                    )}
                    {!organisations && activeOrganisation && (
                        <div className='flex text-lg'>{activeOrganisation.name}</div>
                    )}
                    <div className='opacity-60'><FontAwesomeIcon icon={faUser} className='opacity-60' /> {full_name}</div>
                    {organisations && (
                        <div className='flex items-center gap-2 absolute right-0 bottom-[3rem] opacity-0 hover:opacity-100 transition-all'>
                            <p>{visibilityMode ? 'User' : 'Admin'}</p>
                            <Switch
                                tabIndex={-1}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={changeVisibility}
                            />
                        </div>
                    )}
                </div>
                <Button
                    title={t('sidebar:logout')}
                    className='bg-brand hover:bg-brand-light focus:bg-brand-light'
                    action={modal.withModal({ title: t('modal:title'), body: t('modal:logout') }, handleLogout)}
                    icon={faRightFromBracket}
                />
            </div>
        </>
    );
};

export default SidebarUser;
